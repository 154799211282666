// import { isNavigationFailure, Router } from 'vue-router';
import { Router } from 'vue-router'
// import store from '@/store';
import NProgress from 'nprogress' // progress bar
// import { COOKIE_TOKEN } from '@/constants/http';
import { TOKEN_INFO } from '@/constants/cache'
import { getMetaTitle, hasAuth, Storage } from '@/utils'
import { navigateToException, redirectToLogin } from '@/utils/router'
import { ExceptionEnum, StatisticsPageEnum } from '@/enums'
import { APP_TITLE } from '@/constants/global'
import { isInWujie, WujieBusEnum } from '@common/utils'

NProgress.configure({ showSpinner: false }) // NProgress Configuration

const allowList = ['Login','PhoneSafeguardRightsForm','CSPhoneSafeguardRightsForm', 'icons', 'error', 'error-404'] // no redirect whitelist

const loginRoutePath = '/login'
const defaultRoutePath = '/home'
// 允许直接访问的白名单路径(主要是采集相关页面都是n个页面共用一个路由, 所以这里遇到采集详情页面要直接放行 )
const whitePath = Object.values(StatisticsPageEnum)
export function createRouterGuards(router: Router) {
  router.beforeEach((to, from, next) => {
    NProgress.start() // start progress bar
    const token = Storage.get(TOKEN_INFO)?.token

    if (token) {
      if (to.name === 'Login') {
        next({ path: defaultRoutePath })
        NProgress.done()
      } else if (whitePath.includes(to.params.page as StatisticsPageEnum)) {
        // 采集相关页面都是n个页面共用一个路由, 所以这里遇到采集详情页面要直接放行;
        next()
      } else if (!hasAuth(to.meta.auth)) {
        navigateToException(ExceptionEnum.PAGE_NOT_ACCESS, { replace: false })
      } else {
        // const hasRoute = router.hasRoute(to.name!)
        next()
        // if (to.meta.permissions!) {

        // } else {
        //   next()
        // }
        // 如果不需要每次切换路由获取最新的动态路由，可把下面注释放开
        // if (store.getters['asyncRoute/menus'].length === 0) {
        //   // generate dynamic router
        //   // 防抖获取菜单
        //   const menus = Storage.get(MENUS)
        //   const layout = routes.find((item) => item.name == 'Layout')!
        //   layout.children = [...menus]
        //   router.addRoute(layout)
        //   console.log('router---', router)

        //   store.commit('asyncRoute/setRouters', menus)
        //   // if (allowList.includes(to.name as string) || hasRoute) {
        //   //   // 在免登录名单，直接进入
        //   //   next()
        //   // }
        // }
      }
    } else {
      // not login
      if (allowList.includes(to.name as string)) {
        // 在免登录名单，直接进入
        next()
      } else {
        // if (isInWujie()) {
        //   window.$wujie?.bus.$emit(WujieBusEnum.LOGIN, 'lx')
        // } else {
        //   next({ path: loginRoutePath, query: { redirect: to.fullPath }, replace: true })
        //   Storage.clear()
        // }
        redirectToLogin(to.fullPath)
        NProgress.done() // if current page is login will not trigger afterEach hook, so manually handle it
      }
    }
  })

  router.afterEach(({ meta }) => {
    const title = getMetaTitle(meta)
    document.title = title || APP_TITLE
    // if (isNavigationFailure(failure)) {
    //   console.log('failed navigation', failure);
    // }
    // 在这里设置需要缓存的组件名称
    // const keepAliveComponents = store.state.asyncRoute.keepAliveComponents;
    // const currentComName = to.matched.find(item => item.name == to.name)?.components?.default.name;
    // if (currentComName && !keepAliveComponents.includes(currentComName) && to.meta?.keepAlive) {
    //   // 需要缓存的组件
    //   keepAliveComponents.push(currentComName);
    // } else if (!to.meta?.keepAlive || to.name == 'Redirect') {
    //   // 不需要缓存的组件
    //   const index = store.state.asyncRoute.keepAliveComponents.findIndex(name => name == currentComName);
    //   if (index != -1) {
    //     keepAliveComponents.splice(index, 1);
    //   }
    // }
    // store.commit('asyncRoute/setKeepAliveComponents', keepAliveComponents);
    NProgress.done() // finish progress bar
  })

  // router.onError(error => {
  //   console.log(error, '路由错误');
  // });
}
