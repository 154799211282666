
import { defineComponent } from 'vue';
import SuspenseWithError from '@/components/SuspenseWithError.vue';

export default defineComponent({
  name: 'RouterTransition',
  components: { SuspenseWithError },
  props: {
    notNeedKey: {
      type: Boolean,
      default: false,
    },
    animate: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    return {};
  },
});
