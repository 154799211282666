import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-61f5c9a2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "zf-avatar" }
const _hoisted_2 = { class: "avatar-box" }
const _hoisted_3 = {
  key: 1,
  class: "avatar-box"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_avatar = _resolveComponent("a-avatar")!
  const _component_a_tooltip = _resolveComponent("a-tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.src)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_ctx.isShowTip)
            ? (_openBlock(), _createBlock(_component_a_tooltip, {
                key: 0,
                "popper-class": "avatar-name-tooltip",
                placement: "top"
              }, {
                title: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.avatarName), 1)
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_a_avatar, {
                    size: _ctx.size,
                    src: _ctx.src
                  }, null, 8, ["size", "src"])
                ]),
                _: 1
              }))
            : (_openBlock(), _createBlock(_component_a_avatar, {
                key: 1,
                size: _ctx.size,
                src: _ctx.src
              }, null, 8, ["size", "src"]))
        ], 64))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (_ctx.isShowTip)
            ? (_openBlock(), _createBlock(_component_a_tooltip, {
                key: 0,
                "popper-class": "avatar-name-tooltip",
                placement: "top"
              }, {
                title: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.avatarName), 1)
                ]),
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("div", {
                      class: "name-img",
                      style: _normalizeStyle({
              background: _ctx.bgColor,
              width: _ctx.size + 'px',
              height: _ctx.size + 'px',
              borderRadius: _ctx.size / 2 + 'px'
            })
                    }, [
                      _createElementVNode("span", {
                        class: "name-font",
                        style: _normalizeStyle({ transform: `scale(${_ctx.scaleName})`, fontSize: 12 + 'px' })
                      }, _toDisplayString(_ctx.avatarName.slice(-2)), 5)
                    ], 4)
                  ])
                ]),
                _: 1
              }))
            : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("div", {
                  class: "name-img",
                  style: _normalizeStyle({
            background: _ctx.bgColor,
            width: _ctx.size + 'px',
            height: _ctx.size + 'px',
            borderRadius: _ctx.size / 2 + 'px'
          })
                }, [
                  _createElementVNode("span", {
                    class: "name-font",
                    style: _normalizeStyle({ transform: `scale(${_ctx.scaleName})`, fontSize: 12 + 'px' })
                  }, _toDisplayString(_ctx.avatarName.slice(-2)), 5)
                ], 4)
              ]))
        ], 64))
  ]))
}