// 默认缓存期限为7天
export const DEFAULT_CACHE_TIME = 60 * 60 * 24 * 7
interface CacheOptions {
  prefix?: string
  storage?: Storage
  defaultExpire?: number // 默认过期时间
}
interface CacheSetOptions {
  expire?: number | string
}
/**
 * @example export const cache = new Cache<CacheKeysMap>({ prefix: import.meta.env.VITE_APP_NAME })
 * cache.get('xxx')
 */
export class Cache<KeysMap extends Record<string, any>> {
  protected readonly storage
  protected readonly prefix?: string
  protected readonly defaultExpire: number
  public constructor(options?: CacheOptions) {
    const { prefix, storage = localStorage, defaultExpire = DEFAULT_CACHE_TIME } = options || {}
    this.storage = storage
    this.prefix = prefix ? prefix + '-' : ''
    this.defaultExpire = defaultExpire
  }
  // TODO: 可以改成addEventListener那种类型(在使用此方法的项目中传入泛型)
  // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
  get<T = any>(key: keyof KeysMap): T | null {
    const data = this.storage.getItem(this.getKey(key))
    if (data) {
      const { value, expire } = JSON.parse(data)
      // 在有效期内直接返回
      if (!expire || expire >= Date.now()) {
        return value
      }
      this.remove(this.getKey(key))
    }
    return null
  }
  // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
  set<K extends keyof KeysMap>(key: K, value: KeysMap[K], options?: CacheSetOptions) {
    const expire = options?.expire || this.defaultExpire
    const stringData = JSON.stringify({
      value,
      expire: expire !== null ? new Date().getTime() + Number(expire) * 1000 : null,
    })
    return this.storage.setItem(this.getKey(key), stringData)
  }

  public remove(key: string) {
    return this.storage.removeItem(this.getKey(key))
  }
  // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
  public clear() {
    return this.storage.clear()
  }

  public getKey(key: keyof KeysMap) {
    return `${this.prefix}${String(key)}`
  }

  /**
   * 设置cookie
   * @param {string} name cookie 名称
   * @param {*} value cookie 值
   * @param {number=} expire 过期时间
   * 如果过期时间为设置，默认关闭浏览器自动删除
   * @example
   */
  public setCookie(name: string, value: any, expire: number | null = DEFAULT_CACHE_TIME) {
    document.cookie = `${this.getKey(name)}=${value}; Max-Age=${expire}`
  }

  /**
   * 根据名字获取cookie值
   * @param name
   */
  public getCookie(name: string): string {
    const cookieArr = document.cookie.split('; ')
    for (let i = 0, length = cookieArr.length; i < length; i++) {
      const kv = cookieArr[i].split('=')
      if (kv[0] === this.getKey(name)) {
        return kv[1]
      }
    }
    return ''
  }

  /**
   * 根据名字删除指定的cookie
   * @param {string} key
   */
  public removeCookie(key: string) {
    this.setCookie(key, 1, -1)
  }

  /**
   * 清空cookie，使所有cookie失效
   */
  public clearCookie(): void {
    const keys = document.cookie.match(/[^ =;]+(?==)/g)
    if (keys) {
      for (let i = keys.length; i--; ) {
        document.cookie = keys[i] + '=0;expire=' + new Date(0).toUTCString()
      }
    }
  }
}
