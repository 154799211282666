/**
 * @description 律师行业党建规范化建设情况统计表（2）
 */
export const REPORT_TWO_COLUMNS = [
  {
    title: '单位',
    dataIndex: 'unitName',
    width: 55,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '组织设置-设立党支部的律所数量',
    dataIndex: 'branchFirm',
    width: 63,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '组织设置-设立党总支的律所数量',
    dataIndex: 'totalFirm',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '组织设置-设立基层委员会的律所数量',
    dataIndex: 'jcwyFirm',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '组织设置-联合党支部数量',
    dataIndex: 'lhPartyBranch',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '组织设置-按照“以大带小”“以强带弱”原则组建联合党支部数量',
    dataIndex: 'yddxPartyBranch',
    width: 63,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '组织设置-联合党支部覆盖律所数量',
    dataIndex: 'lhPartyBranchFirm',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '组织设置-选派党建指导员数',
    dataIndex: 'partyBuildingInstructor',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '组织设置-覆盖无党员律所数量',
    dataIndex: 'noPartyFirm',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '组织设置-党建指导员中司法行政机关工作人员和退休政法干警数量',
    dataIndex: 'politicalAgencyStaff',
    width: 63,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '工作机制-本地区律所总数(含无党员律所)',
    dataIndex: 'areaFirmTotal',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '工作机制-已经修改章程的律所数量',
    dataIndex: 'updateRegulation',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '工作机制-由律所主要负责人担任党组织负责人的律所数量',
    dataIndex: 'partyOrgPrincipal',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '工作机制-由律所高级管理人员中的党员担任党组织负责人的律所数量',
    dataIndex: 'gjPartyOrgPrincipal',
    width: 63,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '工作机制-明确党组织在律所发展管理中发挥政治把关作用的律所数量',
    dataIndex: 'partyOrgFirm',
    width: 63,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '工作机制-建立党组织与决策管理层重大问题会商通报等制度的律所数量',
    dataIndex: 'partyOrgBulletinFirm',
    width: 63,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '组织生活-“三会一课”等基本制度落实到位的律所党组织数量',
    dataIndex: 'basicSystemFirm',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '组织生活-推行律师党员联系群众制度的律所党组织数量',
    dataIndex: 'lawyerPartyFirm',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '组织生活-建成规范化党建活动场所的律所党组织数量',
    dataIndex: 'partyActivityFirm',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '组织生活-选树省市两级律师行业党建规范化建设示范所数量',
    dataIndex: 'standardizedConstructionFirm',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '组织生活-培育推广党建规范化建设创新项目数量',
    dataIndex: 'cultivatePartyFirm',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '党员管理-律师党员数量',
    dataIndex: 'lawyerPartyMembers',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '党员管理-行政辅助人员中的党员数量',
    dataIndex: 'xzPartyMembers',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '党员管理-正在培养的入党积极分子数量',
    dataIndex: 'partyActivists',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '党员管理-正在考察的预备党员数量',
    dataIndex: 'probationaryMember',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '党员管理-本年度预备党员转正数量',
    dataIndex: 'turnPositiveMember',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '党员管理-本年度律所党组织受党纪处分数量',
    dataIndex: 'orgPartyDiscipline',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '党员管理-本年度律师党员受党纪处分数量',
    dataIndex: 'partyDiscipline',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '工作保障-律师行业党委配备专职副书记或党务工作人员数量',
    dataIndex: 'viceSecretary',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '工作保障-律所党组织配备的专职党务工作者数量',
    dataIndex: 'partyWorker',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '工作保障-配备专职党务工作者的律所党组织数量',
    dataIndex: 'partyWorkerOrg',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '工作保障-明确将党建经费列入律所管理费用的律所数量',
    dataIndex: 'partyFund',
    width: 31,
    ellipsis: true,
    align: 'center'
  },

  {
    title: '工作保障-是否将党建经费列入协会会费 1:是 2：否',
    dataIndex: 'associationDues',
    width: 79,
    ellipsis: true,
    align: 'center'
  }
];

/**
 * @description 工作保障-是否将党建经费列入协会会费 1:是 2：否
 */
export const IS_MEMBER_OPTION = [
  {
    value: 1,
    label: '是'
  },
  {
    value: 2,
    label: '否'
  }
] as const;

/**
 * @description 工作保障-是否将党建经费列入协会会费 1:是 2：否
 */
export const IS_MEMBER = {
  1: '是',
  2: '否'
};
