import { createApp } from 'vue'
import App from './App.vue'
import { setupRouter } from './router'
// import store from './store';
import { setupStore } from '@/store'
import { setupAntd, setupCustomComponents } from '@/plugins'
import '@/styles/global.less'
import { setupGlobDirectives } from './directives'
import 'uno.css'

let app: ReturnType<typeof createApp>
const setupApp = () => {
	app = createApp(App)

	setupGlobDirectives(app)
	// 注册全局自定义组件,如：<svg-icon />
	setupCustomComponents(app)
	// 注册全局常用的ant-design-vue组件
	setupAntd(app)
	// 挂载vuex状态管理
	setupStore(app)
	setupRouter(app)
	app.mount('#app')
}

// if (isInWujie()) {
//   window.__WUJIE_MOUNT = () => {
//     setupApp()
//   }
//   window.__WUJIE_UNMOUNT = () => {
//     app.unmount()
//   }
// } else {
setupApp()
// }
