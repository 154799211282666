import { MenuModel } from '@/types'
import { RouteRecordName } from 'vue-router'

/**
 * 根据路由名字查找菜单
 * @param name 菜单名
 * @param menus 菜单
 * @returns
 */
export const findMenu = (name: RouteRecordName, menus: MenuModel[]): null | MenuModel => {
  let result: MenuModel | null = null
  menus.forEach((menu) => {
    if (menu.name === name) {
      result = menu
    } else if (menu.subMenu?.length) {
      const newResult = findMenu(name, menu.subMenu)
      if (newResult) {
        result = newResult
      }
    }
  })
  return result
  //  menus.reduce((result, menu) => {
  //   if (menu.name === name) {
  //     return result = menu;
  //   } else if (menu.subMenu?.length) {
  //     findMenu(name, menu.subMenu)
  //   }
  // }, {});
}

export const throttle = (fn: Fn, { interval }: { interval: number }) => {
  let prevTime = 0
  return function (this: any, ...arg: any[]) {
    const currentTime = Date.now()
    if (currentTime - prevTime >= interval) {
      fn.apply(this, arg)
      prevTime = currentTime
    }
  }
}

/**
 *@description 生成指定长度的随机数
 * @param length 随机数长度
 */
export function genRandomString(length: number) {
  const str = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
  let result = ''
  for (let i = length; i > 0; --i)
    // 根据需要的长度做循环
    // 先对字符串集合str随机排列，再随机输出拼接
    result += str[Math.floor(Math.random() * str.length)]
  return result
}

/**
 *@description 生成文件名
 *@param type 文件类型
 */
export function generateFileName(file: File) {
  // 如果上传文件的后缀名包含. 则取.后面的字符作为文件后缀
  const fileNameArr = file.name.includes('.') && file.name.split('.')
  let fileName = Date.now() + genRandomString(6)
  if (fileNameArr) {
    const fileSuffix = fileNameArr[fileNameArr.length - 1]
    fileName += '.' + fileSuffix
  }
  return fileName
}
export function openWindow(
  url: string,
  opt?: { target?: TargetContext | string; noopener?: boolean; noreferrer?: boolean },
) {
  const { target = '__blank', noopener = true, noreferrer = true } = opt || {}
  const feature: string[] = []

  noopener && feature.push('noopener=yes')
  noreferrer && feature.push('noreferrer=yes')

  window.open(url, target, feature.join(','))
}

/**
 * 从数组里获取由指定键名与值名组成的对象
 * @param arr 指定数组
 * @param keyProp 要从数组每一项里获取的键名
 * @param valueProp 要从数组每一项里获取的值的键名
 */
export const getKeyValueFromArr = <T extends any[], K extends keyof T[number], V extends keyof Omit<T[number], K>>(
  arr: T,
  keyProp: K,
  valueProp: V,
): Record<T[number][K], T[number][V]> => {
  return arr.reduce<ReturnType<typeof getKeyValueFromArr>>((acc, item: any) => {
    acc[item[keyProp]] = item[valueProp]
    return acc
  }, {} as ReturnType<typeof getKeyValueFromArr>)
}

/**
 * 从树形结构tree中获取根节点
 * @param value 用来与后代判断的值,值相等则返回true
 * @param prop 需要用来与value比对的treeData内的键名
 * @param treeData 树形结构数据
 */
export const getRootNodeFromTree = <T extends TreeStructure, K extends Extract<keyof T[number], string>>(
  treeData: T,
  prop: K,
  value: T[K],
) => {
  let result: any = null
  treeData.some((item) => {
    if (item[prop] === value) {
      result = item
      return true
    }
    if (item.children?.length && isBelongToTree(item.children, prop, value)) {
      result = item
      return true
    }
    return false
  })
  return result
}

/**
 * value是否属于tree的子节点
 * @param treeData 树形结构数据
 * @param prop 需要用来与value比对的treeData内的键名
 * @param value 用来与子节点判断的值
 */

export const isBelongToTree = <T extends TreeStructure, K extends Extract<keyof T[number], string>>(
  treeData: T,
  prop: K,
  value: T[K],
): boolean => {
  return treeData.some((item) => {
    if (item[prop] === value) {
      return true
    }
    if (item.children?.length) {
      return isBelongToTree(item.children, prop, value)
    }
    return false
  })
}

/**
 * 从treeData获取所有父级
 * @param treeData 树形结构数据
 * @param prop 需要用来与value比对的treeData内的键名
 * @param value 用来与后代判断的值
 */
export const getParentsArrFromTreeData = <T extends TreeStructure, K extends Extract<keyof T[number], string>>(
  treeData: T,
  prop: K,
  value: T[K],
) => {
  const result: T[number][] = []
  treeData.forEach((item) => {
    if (item.children?.length) {
      if (isBelongToTree(item.children, prop, value)) {
        result.push(item)
      }
      result.push(...getParentsArrFromTreeData(item.children, prop, value))
    }
  })
  return result
}



export function getUrlParams(url): any {
  // 通过 ? 分割获取后面的参数字符串
  let urlStr = url.split('?')[1]
  // 创建空对象存储参数
  let obj = {};
  // 再通过 & 将每一个参数单独分割出来
  let paramsArr = urlStr?.split('&')
  for (let i = 0, len = paramsArr?.length; i < len; i++) {
    // 再通过 = 将每一个参数分割为 key:value 的形式
    let arr = paramsArr[i]?.split('=')
    obj[arr[0]] = arr[1];
  }
  return obj
}

export function getUrlParams3(url): any {
  // \w+ 表示匹配至少一个(数字、字母及下划线), [\u4e00-\u9fa5]+ 表示匹配至少一个中文字符
  let pattern = /(\w+|[\u4e00-\u9fa5]+)=(\w+|[\u4e00-\u9fa5]+)/ig;
  let result = {};
  url.replace(pattern, ($, $1, $2) => {
    result[$1] = $2;
  })
  return result
}