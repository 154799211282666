/**
 * 通讯录tabs
 */
export const PHONE_TABS = {
  1: '法院',
  2: '检察院',
  3: '公安局',
  4: '看守所',
  5: '仲裁委员会',
  6: '监狱',
  7: '律师协会',
  8: '律师事务所'
};

export const PHONE_TABS_OPTIONS = [
  {
    value: 1,
    label: '法院'
  },
  {
    value: 2,
    label: '检察院'
  },
  {
    value: 3,
    label: '公安局'
  },
  {
    value: 4,
    label: '看守所'
  },
  {
    value: 5,
    label: '总裁委员会'
  },
  {
    value: 6,
    label: '监狱'
  },
  {
    value: 7,
    label: '律师协会'
  },
  {
    value: 8,
    label: '律师事务所'
  }
];
