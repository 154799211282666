import { RouterTransition } from '@/components/transition'
export const HnZZRoutes = [
  {
    path: '/statistics-hn-zz',
    name: 'StatisticsHnZZ',
    component: RouterTransition,
    meta: {
      title: '数据中心',
      icon: 'icon-daohanglan-shujuzhongxin1',
      hideInMenu: true
    },
    redirect: {
      name: 'StatisticsCollectionHnZZ',
    },
    children: [
      {
        path: '/statistics-hn/collection-hn-zz/:page?',
        name: 'StatisticsCollectionHnZZ',
        component: () => import('@/views/statistics-henan-zz/statisticsCollection.vue'),
        meta: {
          title: '我发布的采集',
          auth: {
          },
        },
      },
      {
        path: '/statistics-hn/submit-hn-zz/:page?',
        name: 'StatisticsSubmitHnZZ',
        component: () => import('@/views/statistics-henan-zz/statisticsSubmit.vue'),
        meta: {
          title: '常规数据月度报送',
          auth: {
          },
        },
      },
      {
        path: 'custom-hn-zz',
        name: 'CustomCollectionHnZZ',
        component: () => import('@/views/statistics-henan-zz/CustomCollection.vue'),
        meta: {
          title: '自定义数据采集',
          auth: {
            // permissions: [PermissionEnum['数据中心.自定义数据采集']],
          },
        },
      },
    ],
  },
]
