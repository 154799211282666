interface Column {
  title: string
  dataIndex: string
  width?: number
  ellipsis: boolean
  align: string
}
/**
 * @description 律师行业党员异动情况统计表（1）
 * 统计
 */
export const REPORT_ONE_EXTEND_COLUMNS: Column[] = [
  {
    title: '上月党员律师人数',
    dataIndex: 'partyLawyerFm',
    width: 179,
    ellipsis: true,
    align: 'center',
  },
  {
    title: '本月党员律师人数',
    dataIndex: 'partyLawyerTm',
    width: 179,
    ellipsis: true,
    align: 'center',
  },
  {
    title: '本月异动总人数',
    dataIndex: 'partyMemberChange',
    width: 179,
    ellipsis: true,
    align: 'center',
  },
  {
    title: '转入人数',
    dataIndex: 'transferIn',
    width: 179,
    ellipsis: true,
    align: 'center',
  },
  {
    title: '转出人数',
    dataIndex: 'transferOut',
    width: 179,
    ellipsis: true,
    align: 'center',
  },
  {
    title: '新增人数',
    dataIndex: 'addPartyLawyer',
    width: 260,
    ellipsis: true,
    align: 'center',
  },
]

/**
 * @description 转入/转出
 */
export const REPORT_ONE_TRANSFER_COLUMNS: Column[] = [
  {
    title: '数据类型',
    dataIndex: 'dataType',
    width: 98,
    ellipsis: true,
    align: 'center',
  },
  {
    title: '姓名',
    dataIndex: 'name',
    width: 119,
    ellipsis: true,
    align: 'center',
  },
  {
    title: '原组织关系所在党组织',
    dataIndex: 'originalPartyOrg',
    width: 179,
    ellipsis: true,
    align: 'center',
  },
  {
    title: '原所在执业机构',
    dataIndex: 'originalOrg',
    width: 179,
    ellipsis: true,
    align: 'center',
  },
  {
    title: '现组织关系所在党组织',
    dataIndex: 'partyOrg',
    width: 179,
    ellipsis: true,
    align: 'center',
  },
  {
    title: '现所在执业机构',
    dataIndex: 'org',
    width: 179,
    ellipsis: true,
    align: 'center',
  },
  {
    title: '备注',
    dataIndex: 'remark',
    width: 221,
    ellipsis: true,
    align: 'center',
  },
]

/**
 * @description 新增
 */
export const REPORT_ONE_ADD_COLUMNS: Column[] = [
  {
    title: '数据类型',
    dataIndex: 'dataType',
    width: 98,
    ellipsis: true,
    align: 'center',
  },
  {
    title: '姓名',
    dataIndex: 'name',
    width: 119,
    ellipsis: true,
    align: 'center',
  },
  {
    title: '现组织关系所在党组织',
    dataIndex: 'partyOrg',
    width: 179,
    ellipsis: true,
    align: 'center',
  },
  // {
  //   title: '原所在执业机构',
  //   dataIndex: 'originalOrg',
  //   width: 179,
  //   ellipsis: true,
  //   align: 'center'
  // },
  // {
  //   title: '现组织关系所在党组织',
  //   dataIndex: 'partyOrg',
  //   width: 179,
  //   ellipsis: true,
  //   align: 'center'
  // },
  {
    title: '现所在执业机构',
    dataIndex: 'org',
    width: 179,
    ellipsis: true,
    align: 'center',
  },
  {
    title: '备注',
    dataIndex: 'remark',
    width: 221,
    ellipsis: true,
    align: 'center',
  },
]
