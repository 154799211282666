export * from './notice'

export { OriginSnEnum } from './file'

export * from './statistics'
export * from './permission'
export * from './exception'
export * from './exam'
/**
 * @description 协会等级
 */
export enum AssociationLevel {
  PROVINCIAL_LEVEL = 1, // 省级
  MUNICIALPAL_LEVEL = 2, // 市级
}

/**
 * @description 角色枚举
 */
export enum RoleEnum {
  PROVINCE = 1, // 省律协
  CITY, // 市级
}
// /**
//  * @description 协会等级对应的详情
//  */
// export const AssociationInfo = {
//   [AssociationLevel.PROVINCIAL_LEVEL]: { name: '省律协' }, // 省级
//   [AssociationLevel.MUNICIALPAL_LEVEL]: { name: '市律协' } // 市级
// }
/**
 * @description 数据采集与填报
 */
export enum DataOperationTypeEnum {
  OPERATION_COLLECTION = 'collection', // 采集(我发布的采集)
  OPERATION_FILL = 'fill', // 填报(我填报的数据)
  OPERATION_LOOK = 'look', // 查看(查看本地律所向省律协填报的采集表)
}

// /**
//  * @description 数据采集与填报
//  */
// export enum PageTypeEnum {
//   PAGE_LIST = 'list', // 列表页
//   PAGE_ADD = 'add', // 添加页
//   PAGE_EDIT = 'edit', // 编辑页
//   PAGE_LOOK = 'look' // 查看页
// }

export * from './report'
