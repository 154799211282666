export enum PermissionEnum {
  '通知公告.我接收的',
  '通知公告.我发送的',
  '数据中心.我发布的采集',
  // '我发送的.常规数据' = 20,
  // '我发送的.常规数据.律师统计表' = 200,
  // '我发送的.常规数据.律师事务所统计表' = 201,
  // 涉外涉港澳台事务统计表 = 202,
  // 公职律师公司律师统计表 = 203,
  // '律师业务统计表（表一）' = 204,
  // '律师业务统计表（表二）' = 205,
  // 律师参政议政统计表 = 206,
  // 律师公益法律服务统计表 = 207,
  // '我发送的.会员数据' = 21,
  // 处分决定统计表 = 210,
  // 律师协会投诉受理查处工作月报表 = 211,
  // 维权案件统计表 = 212,
  // 维护律师执业权利工作月报表 = 213,
  // '我发送的.行业党办数据' = 22,
  // 律师行业党员异动情况统计表 = 220,
  // 律师行业党建规范化建设情况统计表 = 221,
  // 律师行业党建工作统计表 = 222,
  '数据中心.自定义数据采集',
  '数据中心.常规数据月度报送',
  // '常规数据月度报送.律师、行业数据' = 40,
  // '常规数据月度报送.律师业务统计表（表一）' = 400,
  // '常规数据月度报送.律师业务统计表（表二）' = 401,
  // 律师参政议政统计表 = 402,
  // 律师公益法律服务统计表 = 403,
  // 其他数据 = 41,
  // 自定义数据采集 = 410,
  '数据中心.辖内律所上报数据查阅',
  // 律师、行业数据 = 50,
  // 律师业务统计表（表一） =500,
  // 律师业务统计表（表二） =501,
  // 律师参政议政统计表 = 502,
  // 律师公益法律服务统计表 = 503,
  // 其他数据 = 51,
  // 自定义报表 = 510,
  '新闻中心.新闻投稿' = 6,
  '系统设置.用户管理' = 7,
  '系统设置.角色管理' = 8,
  '其他工具.问卷调查' = 60,
}
