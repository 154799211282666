/**
 * @description 公职律师公司律师统计表（7）
 */
export const REPORT_SEVEN_COLUMNS = [
  {
    title: '单位',
    dataIndex: 'associationName',
    width: 63,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '律师类别-律师人数合计',
    dataIndex: 'lawyerTotal',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '律师类别-专职律师',
    dataIndex: 'professionalLawyer',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '律师类别-兼职律师',
    dataIndex: 'partTimeLawyer',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '律师类别-公职律师',
    dataIndex: 'publicLawyer',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '律师类别-公司律师',
    dataIndex: 'corporateLawyer',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '律师类别-法律法援律师',
    dataIndex: 'legalLawyer',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '专业律师-合计',
    dataIndex: 'professionalLawyerTotal',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  // {
  //   title: '公职律师工作情况-对重大 决策、重大行政行为提供法律意见',
  //   dataIndex: 'legalOpinion',
  //   width: 31,
  //   ellipsis: true,
  //   align: 'center'
  // },
  {
    title: '专业律师-刑事',
    dataIndex: 'criminal',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '专业律师-婚姻家事',
    dataIndex: 'marriage',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '专业律师-公司法',
    dataIndex: 'companyLaw',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '专业律师-金融证券保险',
    dataIndex: 'insurance',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '专业律师-房地产',
    dataIndex: 'realEstate',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '专业律师-知识产权',
    dataIndex: 'intellectualProperty',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '专业律师-劳动法',
    dataIndex: 'laborLaw',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '专业律师-涉外法律要务',
    dataIndex: 'foreignLegalMatters',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '专业律师-行政法',
    dataIndex: 'administrativeLaw',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '文化程度-在国境外接受过教育并获得学位的',
    dataIndex: 'returnees',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '文化程度-博士研究生-法律专业',
    dataIndex: 'bsLegalProfession',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '文化程度-博士研究生-非法律专业',
    dataIndex: 'bsNonLegalProfession',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '文化程度-硕士研究生-法律专业',
    dataIndex: 'ssLegalProfession',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '文化程度-硕士研究生-非法律专业',
    dataIndex: 'ssNonLegalProfession',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '文化程度-本科研究生-法律专业',
    dataIndex: 'bkLegalProfession',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '文化程度-本科研究生-非法律专业',
    dataIndex: 'bkNonLegalProfession',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '文化程度-本科以下',
    dataIndex: 'bkBelow',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '年龄结构-29岁以下',
    dataIndex: 'twentyNineAge',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '年龄结构-30-49岁',
    dataIndex: 'thirtyAge',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '年龄结构-50-64岁',
    dataIndex: 'fiftyAge',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '年龄结构-65岁以上',
    dataIndex: 'sixtyFiveAge',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '性别-女律师',
    dataIndex: 'femaleLawyer',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '少数民族律师',
    dataIndex: 'minorityLawyers',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '政治面貌-中共党员',
    dataIndex: 'cpcMember',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '政治面貌-民主党派',
    dataIndex: 'democraticParties',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '政治面貌-无党派人士',
    dataIndex: 'nonpartisan',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '奖惩情况-受（含）以上表彰奖的律师',
    dataIndex: 'recognitionLawyer',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '奖惩情况-受行业处分的律师',
    dataIndex: 'disciplinaryLawyer',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '奖惩情况-训诫',
    dataIndex: 'admonition',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '奖惩情况-警告',
    dataIndex: 'warn',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '奖惩情况-通报批评',
    dataIndex: 'announceCriticism',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '奖惩情况-公开谴责',
    dataIndex: 'publicCondemnation',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '奖惩情况-中止会员权利一个月以上一年以下',
    dataIndex: 'rightSuspend',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '奖惩情况-取消会员资格',
    dataIndex: 'cancelMembership',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '奖惩情况-受行政处罚的律师',
    dataIndex: 'administrativePenalties',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '奖惩情况-警告',
    dataIndex: 'xzAdmonition',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '奖惩情况-罚款',
    dataIndex: 'fine',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '奖惩情况-停止执业三个月以下',
    dataIndex: 'stopPracticeThree',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '奖惩情况-停止执业三个月以上六个月以下',
    dataIndex: 'stopPracticeSix',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '奖惩情况-停止执业六个月以上一年以下',
    dataIndex: 'stopPracticeOneYear',
    width: 31,
    ellipsis: true,
    align: 'center'
  },

  {
    title: '奖惩情况-吊销执业证书',
    dataIndex: 'revokeCertificate',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '调离情况-参加各种培训合计',
    dataIndex: 'trainTotal',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '调离情况-申请律师执业实习人员集中培训',
    dataIndex: 'applyTrain',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '调离情况-律师思想政治培训',
    dataIndex: 'thoughtTrain',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '调离情况-律师执业道德执业纪律培训',
    dataIndex: 'disciplineTrain',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '调离情况-律师业务培训',
    dataIndex: 'businessTrain',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '调离情况-律师赴国外、境外培训',
    dataIndex: 'abroadTrain',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '律所其他人员-申请律师执业实习人员',
    dataIndex: 'applyInterns',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '律所其他人员-调遣外国法律',
    dataIndex: 'foreignLaw',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '律所其他人员-其他人员',
    dataIndex: 'otherPersonnel',
    // width: 31,
    ellipsis: true,
    align: 'center'
  }
];
