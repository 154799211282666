
import { ref, defineComponent } from 'vue';
export default defineComponent({
  name: 'ZfAvatar',
  props: {
    src: { type: String, default: '' }, // 头像地址
    avatarName: { type: String, default: '' }, // 名字
    size: { type: Number, default: 24 }, // 头像大小
    scaleName: { type: Number, default: 1 }, // 没有头像的情况可以缩放字体大小
    isShowTip: { type: Boolean, default: false } // 是否展示el-tooltip
  },
  setup() {
    const bgColor = ref('#625FEC');

    return { bgColor };
  }
});
