import { TreeStructure, ValidKeys, ValueType } from '@common/types'
import { cloneDeep } from 'lodash'
import { isBelongToTree } from '../..'
/**
 * 从treeData获取所有父级
 * @param tree 树形结构数据
 * @param field 需要用来与value比对的treeData内的键名
 * @param value 用来与后代判断的值
 */
export const getParentsFromTree = <
	T extends TreeStructure,
	K extends Extract<keyof T[number], string>,
>(
	tree: T,
	field: K,
	value: T[K],
) => {
	const result: T[number][] = []
	tree.forEach((item) => {
		if (item.children?.length) {
			if (isBelongToTree(item.children, field, value)) {
				result.push(item)
			}
			result.push(...getParentsFromTree(item.children, field, value))
		}
	})
	return result
}

/**
 * 从treeData获取自己的父级
 * @param tree 树形结构数据
 * @param field 需要用来与value比对的treeData内的键名
 * @param value 用来与后代判断的值
 * @deprecated 用Tree findParent代替
 */
export const getParentFromTree = <
	T extends TreeStructure,
	K extends Extract<keyof T[number], string>,
>(
	tree: T,
	field: K,
	value: T[K],
): T[number] | null => {
	const parents = getParentsFromTree(tree, field, value)
	return parents[parents.length - 1] || null
}

/**
 * 从treeData获取所有父级以及自己组成的数组路径
 * @param tree 树形结构数据
 * @param field 需要用来与value比对的treeData内的键名
 * @param value 用来与后代判断的值
 * @deprecated 用Tree findParents代替
 */
export const getPathFromTree = <
	T extends TreeStructure,
	K extends Extract<keyof T[number], string>,
>(
	tree: T,
	field: K,
	value: T[K],
) => {
	const parents = getParentsFromTree(tree, field, value)
	const self = treeFind(tree, field, value)
	return self ? ([...parents, self] as T[number][]) : parents
}
/**
 * 对树的每一层执行数组find方法
 * @param value 用来与后代判断的值
 * @param field 需要用来与value比对的treeData内的键名
 * @param tree 树形结构数据
 * @deprecated 用Tree find代替
 */
export const treeFind = <T extends Record<string, any>, K extends keyof T>(
	tree: T[],
	field: K,
	value: T[K],
	options?: TreeOptions,
): T | null => {
	let result: T | null = null
	const { children: childrenField } = getFieldNames(options)
	tree.some((item) => {
		if (item[field] === value) {
			result = item
			return true
		} else if (item[childrenField]?.length) {
			result = treeFind(item[childrenField], field, value)
			if (result) return true
		}
		return false
	})
	return result
}

/**
 * 对树的每一层执行数组filter方法
 * @param tree 数据源树
 */
export const treeFilter = <T extends Record<string, any>>(
	dataSource: T[],
	predicate: (value: T, index: number, array: T[]) => unknown,
	options?: TreeOptions,
) => {
	const { children: childrenField } = getFieldNames(options)
	const tree = dataSource.filter(predicate)
	tree.forEach((item) => {
		if (item[childrenField]?.length) {
			item[childrenField as keyof T] = treeFilter(item[childrenField], predicate) as any
		}
	})
	return tree
}

const getFieldNames = (options?: TreeOptions) => {
	const { fieldNames } = options || {}
	const { children = 'children' } = fieldNames || {}
	return { children }
}
interface TreeOptions {
	/**
	 * 自定义字段名
	 */
	fieldNames?: {
		/**
		 * 子级字段名
		 */
		children?: string
	}
}
/**
 * 树形结构操作类
 */
export class Tree<T extends Record<string, any>> {
	protected childrenField: string
	public constructor(public data: T[] = [], protected options?: TreeOptions) {
		const { children: childrenField } = getFieldNames(options)
		this.childrenField = childrenField
	}
	// public find<S extends T>(
	// 	predicate: (value: T, index: number, obj: T[]) => value is S,
	// 	thisArg?: any,
	// ): S | undefined
	public find(predicate: (value: T, index: number, obj: T[]) => unknown): T | undefined {
		let result: T | undefined
		let finded = false
		const fn = (currentData: T[]) => {
			result = currentData.find((item, i) => {
				const predicateResult = !!predicate(item, i, this.data)
				if (predicateResult) {
					finded = true
				}
				return predicateResult
			})
			if (!finded) {
				currentData.some((item) => {
					if (item[this.childrenField]?.length) {
						fn(item[this.childrenField])
					}
					return finded
				})
			}
		}
		fn(this.data)
		return result
	}

	// TODO:实现类型
	public map<U>(callbackfn: (value: T, index: number, array: T[]) => U) {
		const fn = (currentData: T[]) => {
			const tree = currentData.map((item, i) => {
				return { ...callbackfn(item, i, this.data), [this.childrenField]: item[this.childrenField] }
			}) as unknown as T[]
			tree.forEach((item) => {
				if (item[this.childrenField]?.length) {
					item[this.childrenField as keyof T] = fn(item[this.childrenField]) as any
				}
			})
			return tree
		}
		return fn(this.data)
	}
	/**
	 * 查找predicate为true时的整条路径
	 */
	public findPath(predicate: (value: T, index: number, obj: T[]) => unknown) {
		let path: T[] = []
		const fn = (currentData: T[], layer = 1, acc: T[] = []) => {
			currentData.some((item, i) => {
				acc = acc.slice(0, layer - 1)
				acc.push(item)
				const predicateResult = !!predicate(item, i, this.data)
				if (predicateResult) {
					path = acc
					return true
				}
				if (item[this.childrenField]?.length) {
					fn(item[this.childrenField], layer + 1, acc)
				}
				return false
			})
		}
		fn(this.data)
		return path
	}
	/**
	 * 查找predicate为true时的所有父级
	 */
	public findParents(predicate: (value: T, index: number, obj: T[]) => unknown) {
		const path = this.findPath(predicate)
		path.pop()
		return path
	}
	/**
	 * foreach
	 */
	public forEach(callbackfn: (value: T, index: number, array: T[]) => void) {
		const fn = (currentData: T[]) => {
			currentData.forEach((item, i) => {
				callbackfn(item, i, this.data)
				if (item[this.childrenField]?.length) {
					fn(item[this.childrenField])
				}
			})
		}
		fn(this.data)
	}
}
