export * from './form'
export * from './uuid'
import { EnumLike, ExtarctKeyValuePairsFromArrObj, ValidKeys } from '@common/types'

type IncludeOption = 'uppercase' | 'lowercase' | 'number'
/**
 *@description 生成指定长度的随机字符串
 * @param length 随机数长度
 */
export function genRandomString(
  length: number,
  options?: {
    /**
     * 生成的字符串中包含什么格式
     */
    include?: IncludeOption | IncludeOption[]
    /**
     * 是否排除易混淆的字符
     */
    excludeConfused?: boolean
    /**
     * 易混淆的字符
     */
    confusedStr?: string
  },
) {
  const { include, excludeConfused = false, confusedStr = 'OIol' } = options || {}
  const strMap: Record<IncludeOption, string> = {
    uppercase: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
    lowercase: 'abcdefghijklmnopqrstuvwxyz',
    number: '0123456789',
  }
  const newStrMap = strMap
  if (excludeConfused) {
    for (const key in strMap) {
      if (Object.hasOwn(strMap, key)) {
        const type = key as IncludeOption
        for (const item of confusedStr) {
          newStrMap[type] = strMap[type].replace(item, '')
        }
      }
    }
  }
  let strSource = ''
  if (Array.isArray(include)) {
    strSource = include.map((item) => newStrMap[item]).join('')
  } else if (typeof include === 'string') {
    strSource = newStrMap[include]
  } else {
    strSource = Object.values(newStrMap).join('')
  }
  const strSourceLen = strSource.length
  let result = ''
  for (let i = length; i > 0; --i)
    // 先对字符串集合str随机排列，再随机输出拼接
    result += strSource[Math.floor(Math.random() * strSourceLen)]
  return result
}
/**
 *@description 生成文件名
 */
export function genFileName(file: File) {
  // 如果上传文件的后缀名包含. 则取.后面的字符作为文件后缀
  const fileNameArr = file.name.includes('.') && file.name.split('.')
  let fileName = Date.now() + genRandomString(6)
  if (fileNameArr) {
    const fileSuffix = fileNameArr[fileNameArr.length - 1]
    fileName += '.' + fileSuffix
  }
  return fileName
}

/**
 * 从enum中提取key(enum中如果值是数字类型则会生成反向映射, 也就是说值也会变成键, 该方法就是为了从enum中排除掉这些由值组成的键)
 */
export const extractKeysFromEnum = <T extends EnumLike>(rawEnum: T) => {
  const result: Extract<keyof T, string>[] = []
  for (const key in rawEnum) {
    if (Object.hasOwn(rawEnum, key) && isNaN(Number(key))) {
      result.push(key)
    }
  }
  return result
}

/**
 * 把类似enum的对象转成数组对象(可用于Select,RadioGroup等组件)
 * @param options.fieldNames 自定义转换后的对象字段名,默认为label与value
 */
export const convertEnumLikeToArrObj = <
  T extends EnumLike,
  LabelField extends string = 'label',
  ValueField extends string = 'value',
>(
  enumLike: T,
  options?: {
    /**
     * 自定义字段
     */
    fieldNames?: {
      label?: LabelField
      value?: ValueField
    }
    /**
     * 是否排除数字类型的key
     */
    excludeNumberKey?: boolean
  },
) => {
  const { fieldNames, excludeNumberKey = true } = options || {}
  const { label = 'label', value = 'value' } = fieldNames || {}
  return (excludeNumberKey ? extractKeysFromEnum(enumLike) : Object.keys(enumLike)).map((item) => ({
    [label]: item,
    [value]: enumLike[item],
  })) as Record<LabelField | ValueField, any>[]
}

/**
 * 从数组里生成由指定键名与值名组成的对象
 * @param arr 指定数组
 * @param keyProp 要从数组每一项里获取的键名
 * @param valueProp 要从数组每一项里获取的值的键名
 */
export const extarctKeyValuePairsFromArrObj = <
  T extends readonly any[],
  K extends ValidKeys<T[number]>,
  V extends keyof Omit<T[number], K>,
>(
  arr: T,
  keyProp: K,
  valueProp: V,
): ExtarctKeyValuePairsFromArrObj<T, K, V> => {
  return arr.reduce((acc, item: T[number]) => {
    acc[item[keyProp]] = item[valueProp]
    return acc
  }, {} as ExtarctKeyValuePairsFromArrObj<T, K, V>)
}
