import { PermissionEnum } from '@/enums'
import { hasAuth, hasPermission } from '@/utils/has'

export type CardType = { icon: string; label: string; name: string; hasAuth?: Fn<boolean> }
/**
 * @description 常用功能
 */
export const COMMON_OPTIONS_CITY: CardType[] = [
  {
    name: 'Notice',
    label: '通知公告',
    icon: 'icon-tongzhigonggao',
    hasAuth: () =>
      hasPermission([PermissionEnum['通知公告.我发送的']]) || hasPermission([PermissionEnum['通知公告.我接收的']]),
  },
  {
    name: 'StatisticsLook',
    label: '数据查看',
    icon: 'icon-shujuchakan',
    hasAuth: () => hasPermission([PermissionEnum['数据中心.辖内律所上报数据查阅']]),
  },
  {
    name: 'StatisticsSubmit',
    label: '数据报送',
    icon: 'icon-shujubaosong',
    hasAuth: () => hasPermission([PermissionEnum['数据中心.常规数据月度报送']]),
  },
]

/**
 * @description 常用功能
 */
export const COMMON_OPTIONS_PROVINCE = [
  {
    name: '律师行业数字驾驶舱',
    label: '律师行业数字驾驶舱',
    icon: 'icon-lvshidaping',
  },
  // {
  //   name: 'StatisticsSubmit',
  //   label: '律所数字驾驶舱',
  //   icon: 'icon-lvsuodaping'
  // },
  {
    name: '行业党建数字驾驶舱',
    label: '行业党建数字驾驶舱',
    icon: 'icon-dangjiandaping',
  },
  {
    name: 'Notice',
    label: '通知公告',
    icon: 'icon-tongzhigonggao',
    hasAuth: () =>
      hasPermission([PermissionEnum['通知公告.我发送的']]) || hasPermission([PermissionEnum['通知公告.我接收的']]),
  },
  {
    name: 'StatisticsCollection',
    label: '数据采集',
    icon: 'icon-shujucaiji',
    hasAuth: () => hasPermission([PermissionEnum['数据中心.我发布的采集']]),
  },
  {
    name: 'LargeDataScreen',
    label: '大屏修改',
    icon: 'icon-shujucaiji',
  },
]

/**
 * @description 通知表头
 */
export const NOTICE_COLUMNS = [
  {
    title: '标题',
    dataIndex: 'title',
    ellipsis: true,
  },
  {
    title: '时间',
    dataIndex: 'ctime',
    ellipsis: true,
  },
]
