/**
 * @description 律师事务所统计表（9）
 */
export const REPORT_NINE_COLUMNS = [
  {
    title: '单位',
    dataIndex: 'associationName',
    width: 70,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '国别及地区-合计',
    dataIndex: 'countryRegionTotal',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '国别及地区-美国',
    dataIndex: 'america',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '国别及地区-欧洲国家',
    dataIndex: 'europe',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '国别及地区-香港',
    dataIndex: 'hongKong',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '国别及地区-其他',
    dataIndex: 'countryRegionOther',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '代表处受处罚情况-罚款',
    dataIndex: 'fine',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '代表处受处罚情况-警告',
    dataIndex: 'warn',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '代表处受处罚情况-责令限期改正',
    dataIndex: 'putRight',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '代表处受处罚情况-责令限期停业',
    dataIndex: 'closeDown',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '代表处受处罚情况-吊销执业执照',
    dataIndex: 'revokeLicense',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '代表受处罚情况-罚款',
    dataIndex: 'dbFine',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '代表受处罚情况-警告',
    dataIndex: 'dbWarn',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '代表处受处罚情况-责令限期停业',
    dataIndex: 'dbCloseDown',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '代表受处罚情况-吊销执业证书',
    dataIndex: 'revokeCertificate',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '港澳台居民在内地（大陆）法律事务所担任执业律师情况-香港',
    dataIndex: 'dlHongKong',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '港澳台居民在内地（大陆）法律事务所担任执业律师情况-澳门',
    dataIndex: 'dlMacao',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '港澳台居民在内地（大陆）法律事务所担任执业律师情况-台湾',
    dataIndex: 'dlTaiwan',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '中国律师事务所涉外法律服务情况-涉外法律服务件数合计',
    dataIndex: 'foreignCaseTotal',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '中国律师事务所涉外法律服务情况-跨境投资并购',
    dataIndex: 'invest',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '中国律师事务所涉外法律服务情况-知识产权',
    dataIndex: 'intellectualProperty',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '中国律师事务所涉外法律服务情况-两反一保',
    dataIndex: 'oneGuarantee',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '中国律师事务所涉外法律服务情况-在境外参与诉讼、仲裁',
    dataIndex: 'foreignArbitration',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '中国律师事务所涉外法律服务情况-其他',
    dataIndex: 'other',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '中国律师事务所涉外法律服务情况-中国国有企业',
    dataIndex: 'stateOwnedEnterprise',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '中国律师事务所涉外法律服务情况-中国民营企业',
    dataIndex: 'privateEnterprise',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '中国律师事务所涉外法律服务情况-其他',
    dataIndex: 'otherEnterprise',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '中国律师事务所涉外法律服务情况-收入额',
    dataIndex: 'income',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '中国律师事务所境外分支机构情况-合计',
    dataIndex: 'branchTotal',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '中国律师事务所境外分支机构情况-美国',
    dataIndex: 'branchAmerica',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '中国律师事务所境外分支机构情况-欧洲国家',
    dataIndex: 'branchEurope',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '中国律师事务所境外分支机构情况-亚洲国家地区',
    dataIndex: 'branchAsia',
    width: 31,
    ellipsis: true,
    align: 'center'
  },

  {
    title: '中国律师事务所境外分支机构情况-其他',
    dataIndex: 'branchOther',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '中国律师事务所境外分支机构情况-业务量',
    dataIndex: 'branchBusinessVolume',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '中国律师事务所境外分支机构情况-收入额',
    dataIndex: 'branchIncome',
    width: 31,
    ellipsis: true,
    align: 'center'
  }
];
