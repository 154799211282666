/**
 * @description 处分决定表头部（3）
 */
export const REPORT_THREE_COLUMNS: {
  title: string
  dataIndex: string
  width?: number
  ellipsis: boolean
  align: string
}[] = [
  {
    title: '序号',
    dataIndex: 'index',
    width: 47,
    ellipsis: true,
    align: 'center',
  },
  {
    title: '年份',
    dataIndex: 'years',
    width: 63,
    ellipsis: true,
    align: 'center',
  },
  {
    title: '月份',
    dataIndex: 'month',
    width: 63,
    ellipsis: true,
    align: 'center',
  },
  {
    title: '所在省份',
    dataIndex: 'province',
    width: 71,
    ellipsis: true,
    align: 'center',
  },
  {
    title: '被处分人',
    dataIndex: 'disposePeople',
    width: 95,
    ellipsis: true,
    align: 'center',
  },
  {
    title: '执业证号',
    dataIndex: 'licenseNumber',
    width: 127,
    ellipsis: true,
    align: 'center',
  },
  {
    title: '被处分人所在律所',
    dataIndex: 'firmName',
    width: 127,
    ellipsis: true,
    align: 'center',
  },
  {
    title: '作出处分的协会',
    dataIndex: 'associationName',
    width: 119,
    ellipsis: true,
    align: 'center',
  },
  {
    title: '违规情形（具体）',
    dataIndex: 'violation',
    width: 263,
    ellipsis: true,
    align: 'center',
  },
  {
    title: '处分结果',
    dataIndex: 'punishedResult',
    width: 180,
    ellipsis: true,
    align: 'center',
  },
]
