import { createRouter, createWebHistory, RouteRecordRaw, useRouter } from 'vue-router'
import { App } from 'vue'
import { createRouterGuards } from './router-guards'
import { RouterTransition } from '@/components/transition'
import { PermissionEnum, RoleEnum } from '@/enums'
import { getEnv } from '@/utils'
import { HnZZRoutes } from '@/views/statistics-henan-zz/routes'
export * from './utils'
export const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Index',
    component: () => import('@/views/index.vue'),
    // component: () => import('@/views/statistics-henan-oa/CustomCollection.vue'),
    // component: () => import('@/views/statistics-henan-oa/statisticsSubmit.vue'),
    // component: () => import('@/views/statistics-henan-oa/CustomCollection.vue'),

    redirect: {
      name: 'Home',
    },
    meta: {
      title: '首页',
    },
    children: [
      {
        path: '/home',
        name: 'Home',
        component: () => import('@/views/home/index.vue'),
        meta: {
          title: '首页',
          hideBreadcrumb: true,
          icon: 'icon-daohanglan-shouye1',
        },
      },
      {
        path: '/notice',
        name: 'Notice',
        component: RouterTransition,
        meta: {
          title: '通知公告',
          icon: 'icon-daohanglan-tongzhigonggao1',
        },
        redirect: {
          name: 'NoticeSend',
        },
        children: [
          {
            path: '/notice/receive/:page?',
            name: 'NoticeReceive',
            component: () => import('@/views/notice/receive.vue'),
            meta: {
              title: '我接收的',
              auth: {
                permissions: [PermissionEnum['通知公告.我接收的']],
              },
            },
          },
          {
            path: '/notice/send/:page?',
            name: 'NoticeSend',
            component: () => import('@/views/notice/send.vue'),
            meta: {
              title: '我发送的',
              auth: {
                permissions: [PermissionEnum['通知公告.我发送的']],
              },
            },
          },
        ],
      },
      {
        path: '/statistics',
        name: 'Statistics',
        component: RouterTransition,
        meta: {
          title: '数据中心',
          icon: 'icon-daohanglan-shujuzhongxin1',
        },
        redirect: {
          name: 'StatisticsCollection',
        },
        children: [
          {
            path: '/statistics/collection/:page?',
            name: 'StatisticsCollection',
            component: () => import('@/views/statistics/statisticsCollection.vue'),
            meta: {
              title: '我发布的采集',
              auth: {
                permissions: [PermissionEnum['数据中心.我发布的采集']],
              },
            },
          },
          {
            path: '/statistics/submit/:page?',
            name: 'StatisticsSubmit',
            component: () => import('@/views/statistics/statisticsSubmit.vue'),
            meta: {
              title: '常规数据月度报送',
              auth: {
                permissions: [PermissionEnum['数据中心.常规数据月度报送']],
              },
            },
          },
          {
            path: '/statistics/look/:page?',
            name: 'StatisticsLook',
            component: () => import('@/views/statistics/statisticsLook.vue'),
            meta: {
              title: '辖内律所上报数据查阅',
              auth: {
                permissions: [PermissionEnum['数据中心.辖内律所上报数据查阅']],
              },
            },
          },
          {
            path: 'custom',
            name: 'CustomCollection',
            component: () => import('@/views/statistics/CustomCollection.vue'),
            meta: {
              title: '自定义数据采集',
              auth: {
                permissions: [PermissionEnum['数据中心.自定义数据采集']],
              },
            },
          },
        ],
      },
      {
        path: '/statistics-hn',
        name: 'StatisticsHn',
        component: RouterTransition,
        meta: {
          title: '数据中心',
          icon: 'icon-daohanglan-shujuzhongxin1',
          hideInMenu: true
        },
        redirect: {
          name: 'StatisticsCollection',
        },
        children: [
          {
            path: '/statistics-hn/collection-hn/:page?',
            name: 'StatisticsCollectionHn',
            component: () => import('@/views/statistics-henan-oa/statisticsCollection.vue'),
            meta: {
              title: '我发布的采集',
              auth: {
              },
            },
          },
          {
            path: '/statistics-hn/submit-hn/:page?',
            name: 'StatisticsSubmitHn',
            component: () => import('@/views/statistics-henan-oa/statisticsSubmit.vue'),
            meta: {
              title: '常规数据月度报送',
              auth: {
              },
            },
          },
          // {
          //   path: '/statistics/look/:page?',
          //   name: 'StatisticsLook',
          //   component: () => import('@/views/statistics/statisticsLook.vue'),
          //   meta: {
          //     title: '辖内律所上报数据查阅',
          //     auth: {
          //       permissions: [PermissionEnum['数据中心.辖内律所上报数据查阅']],
          //     },
          //   },
          // },
          {
            path: 'custom-hn',
            name: 'CustomCollectionHn',
            component: () => import('@/views/statistics-henan-oa/CustomCollection.vue'),
            meta: {
              title: '自定义数据采集',
              auth: {
                // permissions: [PermissionEnum['数据中心.自定义数据采集']],
              },
            },
          },
        ],
      },
      ...HnZZRoutes,
      {
        path: '/phone',
        name: 'Phone',
        component: () => import('@/views/phone/index.vue'),
        meta: {
          title: '行业通讯录',
          hideInMenu: true,
          icon: 'icon-daohanglan-shouye',
        },
      },
      {
        path: '/tools',
        name: 'Tools',
        meta: { title: '其他工具', icon: 'icon-daohanglan-xinwenzhongxin' },
        redirect: { name: 'ToolsContribution' },
        component: RouterTransition,
        children: [
          {
            path: 'contribution-look',
            name: 'ToolsContribution',
            meta: {
              title: '新闻投稿查阅',
              auth: {
                permissions: [PermissionEnum['新闻中心.新闻投稿']],
                roles: [RoleEnum.PROVINCE],
                options: {
                  priority: 'all',
                },
              },
            },
            component: () => import('@/views/tools/Contribution.vue'),
          },
          {
            path: 'contribution',
            name: 'ToolsContributionLook',
            meta: {
              title: '向省律协新闻投稿',
              auth: {
                permissions: [PermissionEnum['新闻中心.新闻投稿']],
                roles: [RoleEnum.CITY],
                options: {
                  priority: 'all',
                },
              },
            },
            component: () => import('@/views/tools/Contribution.vue'),
          },
          {
            path: 'form',
            name: 'CustomForm',
            meta: {
              permissions: [PermissionEnum['其他工具.问卷调查']],
              title: '律师调查问卷',
            },
            component: () => import('@/views/tools/form/index.vue'),
          },
          {
            path: 'questionnaires',
            name: 'Questionnaires',
            meta: {
              title: '问卷详情',
              permissions: [PermissionEnum['其他工具.问卷调查']],
              hideInMenu: true,
            },
            component: () => import('@/views/tools/form/Questionnaires.vue'),
          },
          {
            path: 'questionnairesDetail',
            name: 'QuestionnairesDetail',
            meta: {
              title: '问卷结果详情',
              permissions: [PermissionEnum['其他工具.问卷调查']],
              hideInMenu: true,
            },
            component: () => import('@/views/tools/form/QuestionnairesDetail.vue'),
          },
          {
            path: 'form/create',
            name: 'CreateCustomForm',
            meta: {
              title: '创建律师调查问卷',
              permissions: [PermissionEnum['其他工具.问卷调查']],
              hideInMenu: true,
            },
            component: () => import('@/views/tools/form/Create.vue'),
          },
        ],
      },
      {
        path: '/system',
        name: 'System',
        component: RouterTransition,
        meta: {
          title: '系统设置',
          icon: 'icon-daohanglan-xitongshezhi1',
        },
        redirect: {
          name: 'SystemUser',
        },
        children: [
          {
            path: '/system/user',
            name: 'SystemUser',
            component: () => import('@/views/system/user.vue'),
            meta: {
              title: '用户管理',
              auth: {
                permissions: [PermissionEnum['系统设置.用户管理']],
              },
            },
          },
          {
            path: '/system/role',
            name: 'SystemRole',
            component: () => import('@/views/system/role.vue'),
            meta: {
              title: '角色管理',
              auth: {
                permissions: [PermissionEnum['系统设置.角色管理']],
              },
            },
          },
        ],
      },
      {
        path: '/large-data-screen',
        name: 'LargeDataScreen',
        meta: { hideInMenu: true, auth: [RoleEnum.PROVINCE] },
        redirect: { name: 'LargeDataScreenModify' },
        component: RouterTransition,
        children: [
          {
            path: 'modify',
            name: 'LargeDataScreenModify',
            component: () => import('@/views/large-data-screen/modify/index.vue'),
            redirect: { name: 'LargeDataScreenModifLawyer' },
            children: [
              {
                path: 'lawyer',
                name: 'LargeDataScreenModifLawyer',
                meta: { title: '行业驾驶舱手动录入' },
                component: () => import('@/views/large-data-screen/modify/Lawyer.vue'),
              },
              {
                path: 'party-building',
                name: 'LargeDataScreenModifPartyBuilding',
                meta: { title: '党建驾驶舱手动录入' },
                component: () => import('@/views/large-data-screen/modify/PartyBuilding.vue'),
              },
            ],
          },
        ],
      },
      {
        path: '/exam/questions',
        meta: { title: '会员部在线考试', hideInMenu: true },
        redirect: { name: 'ExamQuestionsCreate' },
        children: [
          {
            path: 'view/:id',
            name: 'ExamQuestionsView',
            component: () => import('@/views/exam/pages/Questions.vue'),
            meta: { title: '查看题库' },
          },
          {
            path: 'create',
            name: 'ExamQuestionsCreate',
            component: () => import('@/views/exam/pages/Questions.vue'),
            meta: { title: '创建题库' },
          },
          {
            path: 'edit/:id',
            name: 'ExamQuestionsEdit',
            component: () => import('@/views/exam/pages/Questions.vue'),
            meta: { title: '编辑题库' },
          },
        ],
      },
      {
        path: '/exception/:code',
        name: 'Exception',
        meta: {
          title: '403 ',
          hideBreadcrumb: true,
          hideInMenu: true,
        },
        component: () => import('@/views/exception/index.vue'),
      },
      {
        path: '/:path(.*)*',
        meta: {
          title: '404 ',
          hideBreadcrumb: true,
          hideInMenu: true,
        },
        redirect: {
          name: 'Exception',
          params: { code: 404 },
        },
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/login/index.vue'),
  },
  // 河南一键维权
  {
    path: '/phone-safeguard-rights-form',
    name: 'PhoneSafeguardRightsForm',
    component: () => import('@/views/data-center/PhoneSafeguardRightsForm.vue'),
  },
  // 长沙一键维权
  {
    path: '/cs-phone-safeguard-rights-form',
    name: 'CSPhoneSafeguardRightsForm',
    component: () => import('@/views/data-center/CSPhoneSafeguardRightsForm.vue'),
  },
]

export const router = createRouter({
  history: createWebHistory(getEnv().VUE_APP_BASE_URL),
  routes,
})


export function setupRouter(app: App) {
  app.use(router)
  // 创建路由守卫
  createRouterGuards(router)
}
