export * from './reg'

import { TreeStructure } from '@common/types'

const toString = Object.prototype.toString

export function is(val: unknown, type: string) {
	return toString.call(val) === `[object ${type}]`
}

export function isDef<T = unknown>(val?: T): val is T {
	return typeof val !== 'undefined'
}

export function isUnDef<T = unknown>(val?: T): val is T {
	return !isDef(val)
}

export function isObject(val: any): val is Record<any, any> {
	return val !== null && is(val, 'Object')
}
export function isFile(val: unknown): val is Record<any, any> {
	return val !== null && is(val, 'File')
}

export function isEmpty<T = unknown>(val: T): val is T {
	if (isArray(val) || isString(val)) {
		return val.length === 0
	}

	if (val instanceof Map || val instanceof Set) {
		return val.size === 0
	}

	if (isObject(val)) {
		return Object.keys(val).length === 0
	}

	return false
}

export function isDate(val: unknown): val is Date {
	return is(val, 'Date')
}

export function isNull(val: unknown): val is null {
	return val === null
}

export function isNullAndUnDef(val: unknown): val is null | undefined {
	return isUnDef(val) && isNull(val)
}

export function isNullOrUnDef(val: unknown): val is null | undefined {
	return isUnDef(val) || isNull(val)
}

export function isNumber(val: unknown): val is number {
	return is(val, 'Number')
}

export function isPromise<T = any>(val: unknown): val is Promise<T> {
	return is(val, 'Promise') && isObject(val) && isFunction(val.then) && isFunction(val.catch)
}

export function isString(val: unknown): val is string {
	return is(val, 'String')
}

export function isFunction(val: unknown): val is Function {
	return typeof val === 'function'
}

export function isBoolean(val: unknown): val is boolean {
	return is(val, 'Boolean')
}

export function isRegExp(val: unknown): val is RegExp {
	return is(val, 'RegExp')
}

export function isArray(val: any): val is Array<any> {
	return val && Array.isArray(val)
}

export function isWindow(val: any): val is Window {
	return typeof window !== 'undefined' && is(val, 'Window')
}

export function isElement(val: unknown): val is Element {
	return isObject(val) && !!val.tagName
}

export function isMap(val: unknown): val is Map<any, any> {
	return is(val, 'Map')
}
export const isServer = typeof window === 'undefined'

export const isClient = !isServer

/**
 * 是否整数
 */
export const isInt = (val: unknown) => Number(val) % 1 === 0

export function isUrl(path: string): boolean {
	const reg =
		/(((^https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(?::\d+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)$/
	return reg.test(path)
}

/**
 * value是否属于tree的子节点
 * @param tree 树形结构数据
 * @param prop 需要用来与value比对的treeData内的键名
 * @param value 用来与子节点判断的值
 */

export const isBelongToTree = <T extends TreeStructure, K extends Extract<keyof T[number], string>>(
	tree: T,
	prop: K,
	value: T[K],
): boolean => {
	return tree.some((item) => {
		if (item[prop] === value) {
			return true
		} else if (item.children?.length) {
			return isBelongToTree(item.children, prop, value)
		}
		return false
	})
}

export const isRem = (str: string) => {
	return str.toLowerCase().includes('rem')
}
