/**
 * 验证电话 手机、固话、400
 */
export const checkTel = val => {
  const reg = /^(\d3,4|\d{3,4}-)?\d{7,8}$/; // XXXX-XXXXXXX，XXXX-XXXXXXXX，XXX-XXXXXXX，XXX-XXXXXXXX，XXXXXXX，XXXXXXXX 格式
  return reg.test(val);
};

export const checkPhone = val => {
  const reg = /^(1)[0-9]{10}$/;
  return reg.test(val);
};

export const checkOrgTel = val => {
  const reg = /^400([0-9]){7}$/;
  return reg.test(val);
};

/**
 * 验证金额小数点前15位，保留两位小数
 */
export const moneyTest = val => {
  const reg = /^(([1-9]\d{0,14}|^0)(\.\d{1,2})?$)|(^0\.\d{1,2}$)/g;
  return reg.test(val);
};
