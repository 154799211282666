/**
 * 每组数字多少位
 */
const PER_GROUP_LENGTH = 4
/**
 * 一组数字里面的单位,
 */
enum UnitLength {
	拾 = 2,
	佰,
	仟,
}
/**
 * 汉字对应的大写
 */
enum Hanzis {
	零,
	壹,
	贰,
	叁,
	肆,
	伍,
	陆,
	柒,
	捌,
	玖,
}
/**
 * 所有单位, 会被增加到除最后一组数字的后面
 */
const units = ['兆', '万亿', '亿', '万']
const Yuan = '元'
const CNYUnits = ['角', '分']
/**
 * 最大支持位数
 */
const MAX_COUNT = 20
/**
 * 对传入的数字格式化成字符串后分割成数组并分成n组,每组count个
 */
const groupBy = (num: number | bigint | string, count: number) => {
	const arr = String(num).split('')
	const result: string[][] = []
	for (let i = arr.length; i > 0; i -= count) {
		const start = Math.max(i - count, 0)
		result.push(arr.slice(start, i))
	}
	return result.reverse().map((item) => {
		// 当不足count位时补齐
		while (item.length !== count) {
			item.unshift('0')
		}
		return item
	})
}
/**
 * 最后一位是否是0
 */
export const lastIsZero = (str: string) => str.at(-1) === Hanzis[0]
/**
 * 最后一位是否是单位
 */
export const lastIsUnit = (str: string) => {
	const last = str.at(-1)
	return !!last && units.includes(last)
}
/**
 * 数字转大写
 */
export const num2uppercase = (
	num: number | bigint | string,
	options?: {
		ignoreContinuousZero: boolean
		addCNYUnit?: boolean
	},
) => {
	const { ignoreContinuousZero = true, addCNYUnit = false } = options || {}
	// 过滤特殊字符并去掉前面的所有0
	const stringifyNum = String(num)
		.replace(/[^\d.]/g, '')
		.replace(/^0+/g, '')
	const [integer, decimal = ''] = stringifyNum.split('.')
	if (integer.length > MAX_COUNT) {
		return '超过支持的最大数值'
	}

	const numGroups = groupBy(integer, PER_GROUP_LENGTH)

	// 当不足MAX_COUNT / PER_GROUP_LENGTH组时补齐, 便于计算
	while (numGroups.length < MAX_COUNT / PER_GROUP_LENGTH) {
		numGroups.unshift([])
	}
	let integerResult = ''
	let decimalResult = ''
	if (ignoreContinuousZero) {
		// 后面的每组数字是否都为0
		let lastAllIsZero = false
		integerResult = numGroups.reduce((groupAcc, groupItem, groupIndex) => {
			groupAcc += groupItem.reduce((acc, item, index) => {
				const currentNum = Number(item)
				const groupUnit = units[groupIndex] || ''
				const currentAcc = groupAcc + acc
				if (currentNum === 0) {
					if (lastAllIsZero) {
						return acc + (lastIsUnit(currentAcc) || currentAcc === '' ? '' : groupUnit)
					}
					const rightGroups = numGroups.slice(groupIndex + 1, numGroups.length)
					const isAllZero =
						// eslint-disable-next-line max-nested-callbacks
						!!rightGroups.length && rightGroups.every((item) => item.every((item) => Number(item) === 0))
					// 当前数字为0的情况下 如果后面的全是0先原样返回等待在下一次循环中处理
					if (isAllZero) {
						lastAllIsZero = true
						return acc
					}
					// 是该组数字的最后一位时
					if (index === PER_GROUP_LENGTH - 1) {
						// 当前数字为0的情况下 最后一位等于0并且当前不是最后一组数字(也就是个十百千那一组)时在后面加上零
						return acc + groupUnit + (groupIndex !== numGroups.length - 1 ? Hanzis[0] : '')
					}
					// 当前数字为0并且同一组下一位也为0时什么也不加
					if (groupItem[index + 1] === '0') {
						return acc
					}
					// 当前数字为0的情况下 只要当前累计字符串不为空并且最后一位不是零就在后面加上零
					if (currentAcc !== '' && !lastIsZero(currentAcc)) {
						return acc + Hanzis[0]
					}
				} else if (index !== PER_GROUP_LENGTH - 1) {
					// 不是最后一位直接输出值的大写+对应的值的单位
					return acc + Hanzis[currentNum] + UnitLength[PER_GROUP_LENGTH - index]
				} else {
					// 是最后一位直接输出值的大写+这一组的单位
					return acc + Hanzis[currentNum] + groupUnit
				}
				return acc
			}, '')
			return groupAcc
		}, '')
	} else {
		const reversedInterge = String(integer).split('').reverse()
		const integerLen = reversedInterge.length
		for (let i = 0; i < integerLen; i++) {
			const ele = Number(reversedInterge[i])
			let hanzi = Hanzis[ele]
			// 当i为4 8 12时需要加上单位
			if (i !== 0 && i % PER_GROUP_LENGTH === 0) {
				const num = Math.floor(i / PER_GROUP_LENGTH)
				integerResult = hanzi + units[units.length - num] + integerResult
			} else {
				const unit = UnitLength[(i % PER_GROUP_LENGTH) + 1]
				if (unit) {
					hanzi = hanzi + unit
				}
				integerResult = hanzi + integerResult
			}
		}
	}
	for (const item of decimal) {
		decimalResult += Hanzis[Number(item)]
	}
	if (integerResult === '') {
		integerResult = Hanzis[0]
	}
	if (addCNYUnit) {
		integerResult += Yuan
		let decimalWithCNYUnits = ''
		for (let i = 0; i < decimalResult.length; i++) {
			const element = decimalResult[i]
			decimalWithCNYUnits += element + (CNYUnits[i] || '')
		}
		decimalResult = decimalWithCNYUnits
	}
	return integerResult + (decimalResult ? (addCNYUnit ? '' : '点') + decimalResult : '')
}
