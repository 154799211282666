/**
 * @description 律师业务情况数据采集表一（12）
 */
export const REPORT_TWELVE_COLUMNS = [
  {
    title: '单位',
    dataIndex: 'unitName',
    width: 70,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '刑事案件辩护及代理-刑事案件律师辩护覆盖率',
    dataIndex: 'criminalCaseCoverage',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '刑事案件辩护及代理-人民法院审理的刑事案件',
    dataIndex: 'courtCriminalCase',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '刑事案件辩护及代理-审判阶段有辩护律师的刑事案件',
    dataIndex: 'trialStageCriminalCase',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '刑事案件辩护及代理-合计',
    dataIndex: 'criminalCaseTotal',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '刑事案件辩护及代理-刑事案件辩护',
    dataIndex: 'criminalCaseDefend',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '刑事案件辩护及代理-当事人自行委托辩护',
    dataIndex: 'selfDefense',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '刑事案件辩护及代理-依申请的刑事法律援助',
    dataIndex: 'legalAid',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '刑事案件辩护及代理-法定通知辩护',
    dataIndex: 'noticeDefense',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '刑事案件辩护及代理-扩大通知辩护',
    dataIndex: 'expandNoticeDefense',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '刑事案件辩护及代理-刑事案件代理',
    dataIndex: 'criminalCaseProxy',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '民事案件代理-合计',
    dataIndex: 'civilCase',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '民事案件代理-婚姻家庭纠纷案件',
    dataIndex: 'familyDisputeCase',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '民事案件代理-公司案件',
    dataIndex: 'companyCase',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '民事案件代理-金融银行案件',
    dataIndex: 'bankCase',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '民事案件代理-证券纠纷案件',
    dataIndex: 'securitiesCase',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '民事案件代理-保险纠纷案件',
    dataIndex: 'insuranceCase',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '民事案件代理-海事海商案件',
    dataIndex: 'maritimeCase',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '民事案件代理-建设工程与房地产案件',
    dataIndex: 'estateCase',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '民事案件代理-劳动争议案件',
    dataIndex: 'laborCase',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '民事案件代理-知识产权案件',
    dataIndex: 'knowledgeCase',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '民事案件代理-破产与重组案件',
    dataIndex: 'bankruptCase',
    width: 31,
    ellipsis: true,
    align: 'center'
  },

  {
    title: '民事案件代理-医疗纠纷案件',
    dataIndex: 'medicalTreatment',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '民事案件代理-其他',
    dataIndex: 'civilCaseOther',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '行政案件代理',
    dataIndex: 'administrationCase',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '代理申诉',
    dataIndex: 'proxyAppeal',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '非诉讼法律服务-合计',
    dataIndex: 'nonLitigationTotal',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '非诉讼法律服务-公司法',
    dataIndex: 'companyLaw',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '非诉讼法律服务-金融银行',
    dataIndex: 'financialBank',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '非诉讼法律服务-证券',
    dataIndex: 'securities',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '非诉讼法律服务-保险',
    dataIndex: 'insurance',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '非诉讼法律服务-反垄断',
    dataIndex: 'antitrust',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '非诉讼法律服务-建设工程与房地产',
    dataIndex: 'realEstate',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '非诉讼法律服务-知识产权',
    dataIndex: 'intellectualProperty',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '非诉讼法律服务-税法',
    dataIndex: 'tariffLaw',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '非诉讼法律服务-劳动法',
    dataIndex: 'laborLaw',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '非诉讼法律服务-海事海商',
    dataIndex: 'maritime',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '非诉讼法律服务-环境资源与能源',
    dataIndex: 'energy',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '非诉讼法律服务-破产与重组',
    dataIndex: 'bankrupt',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '非诉讼法律服务-其他',
    dataIndex: 'nonLitigationOther',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '咨询和代书',
    dataIndex: 'consult',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '仲裁业务-合计',
    dataIndex: 'arbitrationBusinessTotal',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '仲裁业务-劳动争议仲裁',
    dataIndex: 'laborArbitration',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '仲裁业务-国际商事仲裁',
    dataIndex: 'internationalityArbitration',
    width: 31,
    ellipsis: true,
    align: 'center'
  }
];
