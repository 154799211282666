import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, Suspense as _Suspense, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.error)
    ? _renderSlot(_ctx.$slots, "error", { key: 0 }, () => [
        _createTextVNode(_toDisplayString(_ctx.error), 1)
      ])
    : (_openBlock(), _createBlock(_Suspense, { key: 1 }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default")
        ]),
        fallback: _withCtx(() => [
          _renderSlot(_ctx.$slots, "fallback")
        ]),
        _: 3
      }))
}