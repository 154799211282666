type SortCb<T> = (item: T) => number
/**
 * 基于冒泡排序对数组内的成员进行排序, 会改变原数组
 * @param arr 要排序的原数组
 * @param cb 在每次排序时都会调用的回调函数, 返回作为排序依据的值
 * @returns 排序完成的数组
 */
export const bubbleSort = <T>(arr: T[], cb: SortCb<T>) => {
	const len = arr.length
	let boundary = len - 1
	for (let i = 0; i < len; ++i) {
		let lastSwapIndex = -1
		for (let j = 0; j < boundary; ++j) {
			if (cb(arr[j]) > cb(arr[j + 1])) {
				;[arr[j], arr[j + 1]] = [arr[j + 1], arr[j]]
				lastSwapIndex = j
			}
		}
		if (lastSwapIndex < 0) break
		boundary = lastSwapIndex
	}
	return arr
}
// console.log(bubbleSort([3, 5, 6, 15, 8, 88, 87], (item) => item))
// console.log(bubbleSort([{ value: 5 }, { value: 1 }, { value: 3 }, { value: 0 }], (item) => item.value))
// console.log(
// 	bubbleSort(
// 		[
// 			2, 55, 99, 2, 55, 1, 20, 45, 100, 2, 88, 566, 2, 100, 55, 2, 55, 99, 2, 55, 1, 20, 5, 100, 2, 88, 66, 2, 100, 55,
// 			2, 55, 99, 2, 55, 1, 20, 5, 10086, 100, 9797, 45, 100, 2, 88, 566, 2, 100, 55, 2, 55, 99, 2, 55, 1, 20, 5, 100, 2,
// 			88, 66, 2, 100, 55, 2, 55, 99, 2, 55, 1, 20, 5, 100, 9797, 2, 88, 66, 2, 100, 552, 55, 99, 2, 55, 1, 20, 5, 100,
// 			2, 88, 66, 2, 100, 55,
// 		],
// 		(item) => item,
// 	),
// )

/**
 * 基于插入排序对数组内的成员进行排序, 会改变原数组
 * @param arr 要排序的原数组
 * @param cb 在每次排序时都会调用的回调函数, 返回作为排序依据的值
 * @returns 排序完成的数组
 */
export const insertionSort = <T>(arr: T[], cb: SortCb<T>) => {
	const len = arr.length
	for (let i = 1; i < len; ++i) {
		const element = arr[i]
		let j = i - 1
		while (j >= 0) {
			// 从已排序区间中找到合适的位置
			if (cb(arr[j]) > cb(element)) {
				// 往后移动一位,让出位置
				arr[j + 1] = arr[j]
				--j
			} else {
				break
			}
		}
		arr[j + 1] = element
	}
	return arr
}
// console.log(insertionSort([3, 1, 5, 2, 1541, 8], (item) => item))
// console.log(insertionSort([{ value: 5 }, { value: 1 }, { value: 3 }, { value: 0 }], (item) => item.value))
/**
 * 基于选择排序对数组内的成员进行排序, 会改变原数组
 * @param arr 要排序的原数组
 * @param cb 在每次排序时都会调用的回调函数, 返回作为排序依据的值
 * @returns 排序完成的数组
 */
export const selectionSort = <T>(arr: T[], cb: SortCb<T>) => {
	const len = arr.length
	for (let i = 0; i < len; ++i) {
		let minIndex = i
		// 从已排序区间中选一个最小得值
		for (let j = i + 1; j < len; j++) {
			if (cb(arr[j]) < cb(arr[minIndex])) {
				minIndex = j
			}
		}
		// 交换最小的值与已排序区间的下一个值的位置
		;[arr[minIndex], arr[i]] = [arr[i], arr[minIndex]]
	}
	return arr
}
// console.log(selectionSort([3, 5, 2, 15, 8, 88], (item) => item))
// console.log(selectionSort([{ value: 5 }, { value: 1 }, { value: 3 }, { value: 0 }], (item) => item.value))

/**
 * 基于归并排序对数组内的成员进行排序, 不会改变原数组
 * @param arr 要排序的原数组
 * @param cb 在每次排序时都会调用的回调函数, 返回作为排序依据的值
 * @returns 排序完成的数组
 */
export const mergeSort = <T>(arr: T[], cb: SortCb<T>) => {
	const len = arr.length
	if (len < 2) {
		return arr
	}
	const mid = Math.floor(len / 2)
	const leftRes = mergeSort(arr.slice(0, mid), cb)
	const rightRes = mergeSort(arr.slice(mid, len), cb)
	let leftIndex = 0
	let rightIndex = 0
	const result: T[] = []
	while (leftIndex < leftRes.length && rightIndex < rightRes.length) {
		if (cb(leftRes[leftIndex]) <= cb(rightRes[rightIndex])) {
			result.push(leftRes[leftIndex++])
		} else {
			result.push(rightRes[rightIndex++])
		}
	}
	result.push(...leftRes.slice(leftIndex), ...rightRes.slice(rightIndex))
	return result
}
// console.log(mergeSort([3, 5, 2, 19, 55, 1], (item) => item))
// console.log(mergeSort([{ value: 5 }, { value: 1 }, { value: 3 }, { value: 3 }, { value: 0 }], (item) => item.value))
/**
 * 基于快速排序对数组内的成员进行排序, 会改变原数组
 * @param arr 要排序的原数组
 * @param cb 在每次排序时都会调用的回调函数, 返回作为排序依据的值
 * @returns 排序完成的数组
 */
export const quickSort = <T>(arr: T[], cb: SortCb<T>) => {
	if (arr.length < 2) {
		return arr
	}
	const partition = (startIndex: number, endIndex: number) => {
		const len = endIndex - startIndex + 1
		// 采用三数取中法确定pivot
		let num1 = startIndex
		let num2 = Math.floor(len / 2) + startIndex
		let num3 = len - 1
		if (cb(arr[num1]) > cb(arr[num2])) {
			;[num2, num1] = [num1, num2]
		}
		if (cb(arr[num2]) > cb(arr[num3])) {
			;[num2, num3] = [num3, num2]
		}
		// 上面两个判断已经确定了end是最大的, 所以这里只需要判断前两个数的大小即可
		if (cb(arr[num1]) > cb(arr[num2])) {
			;[num2, num1] = [num1, num2]
		}
		// 将pivot放置在数组最后
		;[arr[endIndex], arr[num2]] = [arr[num2], arr[endIndex]]
		const pivot = arr[endIndex]
		let j = startIndex
		for (let i = j; i < endIndex; i++) {
			if (cb(arr[i]) < cb(pivot)) {
				;[arr[j], arr[i]] = [arr[i], arr[j]]
				j++
			}
		}
		;[arr[endIndex], arr[j]] = [arr[j], arr[endIndex]]
		return j
	}
	const fn = <T>(arr: T[], cb: SortCb<T>, startIndex: number, endIndex: number) => {
		const pivotIndex = partition(startIndex, endIndex)
		if (pivotIndex - startIndex >= 2) {
			fn(arr, cb, startIndex, pivotIndex - 1)
		}
		if (endIndex - pivotIndex >= 2) {
			fn(arr, cb, pivotIndex + 1, endIndex)
		}
	}
	fn(arr, cb, 0, arr.length - 1)
	return arr
}
// console.log(quickSort([2, 1], (item) => item))
// console.log(selectionSort([{ value: 5 }, { value: 1 }, { value: 3 }, { value: 0 }], (item) => item.value))
// console.log(
// 	quickSort(
// 		[
// 			2, 55, 99, 2, 55, 1, 20, 45, 100, 2, 88, 566, 2, 100, 55, 2, 55, 99, 2, 55, 1, 20, 5, 100, 2, 88, 66, 2, 100, 55,
// 			2, 55, 99, 2, 55, 1, 20, 5, 10086, 100, 9797, 45, 100, 2, 88, 566, 2, 100, 55, 2, 55, 99, 2, 55, 1, 20, 5, 100, 2,
// 			88, 66, 2, 100, 55, 2, 55, 99, 2, 55, 1, 20, 5, 100, 9797, 2, 88, 66, 2, 100, 552, 55, 99, 2, 55, 1, 20, 5, 100,
// 			2, 88, 66, 2, 100, 55,
// 		],
// 		(item) => item,
// 	),
// )
