/**
 * @description 全省律师业务情况统计数据采集表二（13）
 */
export const REPORT_THIRTEEN_COLUMNS = [
  {
    title: '单位',
    dataIndex: 'unitName',
    width: 80,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '法律顾问-合计',
    dataIndex: 'legalCounselTotal',
    width: 43,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '法律顾问-担任党政机关，人民团体法律顾问',
    dataIndex: 'partyLegalCounsel',
    width: 43,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '法律顾问-担任企业法律顾问',
    dataIndex: 'enterpriseLegalCounsel',
    width: 43,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '法律顾问-担任事业单位法律顾问',
    dataIndex: 'careerLegalCounsel',
    width: 43,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '法律顾问-其他',
    dataIndex: 'otherLegalCounsel',
    width: 43,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '业务情况（按收费金额）-办理各类法律事务件数合计',
    dataIndex: 'legalAffairsTotal',
    width: 43,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '业务情况（按收费金额）-免费',
    dataIndex: 'free',
    width: 43,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '业务情况（按收费金额）-5000元以下',
    dataIndex: 'fiveThousandBelow',
    width: 43,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '业务情况（按收费金额）-5000元至1万元',
    dataIndex: 'fiveThousand',
    width: 43,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '业务情况（按收费金额）-1万元至5万元',
    dataIndex: 'tenThousand',
    width: 43,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '业务情况（按收费金额）-5万至10万',
    dataIndex: 'fiftyThousand',
    width: 43,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '业务情况（按收费金额）-10万至50万',
    dataIndex: 'oneHundredThousand',
    width: 43,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '业务情况（按收费金额）-50万至100万',
    dataIndex: 'fiveHundredThousand',
    width: 43,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '业务情况（按收费金额）-100万至500万',
    dataIndex: 'oneMillion',
    width: 43,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '业务情况（按收费金额）-500万元以上',
    dataIndex: 'fiveMillionAbove',
    width: 43,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '业务情况（按收费金额）-办理各类法律事务件数合计',
    dataIndex: 'caseTotal',
    width: 43,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '业务情况（按收费金额）-小额收费案件',
    dataIndex: 'smallCase',
    width: 43,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '业务情况（按收费金额）-小额收费案件占比',
    dataIndex: 'smallCasePercentage',
    width: 43,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '业务收费-业务收费合计',
    dataIndex: 'businessChargesTotal',
    width: 43,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '业务收费-刑事案件收费',
    dataIndex: 'criminalCaseCharge',
    width: 43,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '业务收费-民事案件收费',
    dataIndex: 'civilCaseCharge',
    width: 43,
    ellipsis: true,
    align: 'center'
  },

  {
    title: '业务收费-行政案件收费',
    dataIndex: 'administrativeCaseCharge',
    width: 43,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '业务收费-非诉讼法律事务收费',
    dataIndex: 'nonLitigationCharge',
    width: 43,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '业务收费-律师事务所设立的调解工作室调解案件收费',
    dataIndex: 'caseCharge',
    width: 43,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '业务收费-其他',
    dataIndex: 'otherCharge',
    width: 43,
    ellipsis: true,
    align: 'center'
  }
];
