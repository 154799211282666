/**
 * @description 维护律师执业权利工作月报表（5）
 */
export const REPORT_FIVE_COLUMNS = [
  {
    title: '单位',
    dataIndex: 'dataUnit',
    width: 79,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '接待维权数',
    dataIndex: 'maintenancePowerNum',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '申请维权方式-来信',
    dataIndex: 'letter',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '申请维权方式-来访',
    dataIndex: 'comeVisit',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '申请维权方式-来电',
    dataIndex: 'phoneCall',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '申请维权方式-网上',
    dataIndex: 'internet',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '申请维权方式-上级律协转办',
    dataIndex: 'associationTransfer',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '申请维权方式-下级律协协调',
    dataIndex: 'lowerAssociationTransfer',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '申请维权方式-同级律协协助',
    dataIndex: 'sameAssociationTransfer',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '申请维权方式-司法局转移',
    dataIndex: 'judicialBureauTransfer',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '侵权类型-会见权',
    dataIndex: 'meetRight',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '侵权类型-阅卷权',
    dataIndex: 'readRight',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '侵权类型-立案权',
    dataIndex: 'caseRight',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '侵权类型-调查取证权',
    dataIndex: 'investigationRight',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '侵权类型-控告权',
    dataIndex: 'accuseRight',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '侵权类型-提出法律意见权',
    dataIndex: 'expressViewsRight',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '侵权类型-知情、发问、质证、辩论、通信权',
    dataIndex: 'communicationRight',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '侵权类型-受到侮辱、诽谤、威胁、报复、人身伤害权',
    dataIndex: 'insultedRight',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '侵权类型-庭审过程中被违反规定打断或者制止权',
    dataIndex: 'stopRight',
    width: 63,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '侵权类型-被违反规定强行带出法庭',
    dataIndex: 'forciblyOutCourt',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '侵权类型-被非法关押、扣留、拘禁或者以其他方式限制人身自由',
    dataIndex: 'restrictionFreedom',
    width: 63,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '侵权类型-其他妨碍依法履行辩护、代理职责、侵犯执业权利',
    dataIndex: 'infringementPractice',
    width: 63,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '侵权单位-人民法院',
    dataIndex: 'court',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '侵权单位-检察院',
    dataIndex: 'procuratorate',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '侵权单位-公安机关',
    dataIndex: 'safetyAgency',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '侵权单位-对方当事人',
    dataIndex: 'opposingParty',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '侵权单位-其他',
    dataIndex: 'other',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '受理数',
    dataIndex: 'agreeNum',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '正在处理数',
    dataIndex: 'activeNum',
    width: 47,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '成功解决数',
    dataIndex: 'successfulNum',
    width: 47,
    ellipsis: true,
    align: 'center'
  }
];

/**
 * @description 维护律师执业权利工作月报表（5）单位
 */
export const REPORT_FIVE_COLUMNS_UNIT = {
  1: '当月',
  2: '目前累计'
};
