import { LocalStorageKeys } from '@/constants'
import { IStore } from '@/store/types'
import { cache } from '@/utils'
import { RouteRecordRaw } from 'vue-router'

export type AppState = {
  menus: RouteRecordRaw[]
  keepAliveComponents: string[]
}

export const state: AppState = cache.get<IStore>(LocalStorageKeys.GLOBAL_STATE)?.app || {
  menus: [],
  keepAliveComponents: [],
}
