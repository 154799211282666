import { UserState } from './state'

export const mutations = {
  /**
   * @description 设置用户信息
   * @param state
   * @param userInfo
   */
  setUserInfo: (state: UserState, userInfo: UserState['userInfo']): void => {
    state.userInfo = userInfo
  },
  /**
   * @description 设置token信息
   * @param state
   * @param tokenInfo
   */
  // setTokenInfo: (state: UserState, tokenInfo: TokenInfo): void => {
  //   // 设置动态路由
  //   state.tokenInfo = tokenInfo
  // },
}
