import { UserInfo } from '@/types'
import { getLocalGlobalState } from '@/utils/state'

export type UserState = {
  // tokenInfo: TokenInfo
  userInfo?: UserInfo
}

export const state: UserState =
  getLocalGlobalState()?.user ||
  {
    // tokenInfo: {
    //   expireTime: '', // 过期时间
    //   refreshToken: '', // 刷新token令牌
    //   token: '', // token
    // },
    // userInfo: {
    //   associationLevel: AssociationLevel.MUNICIALPAL_LEVEL, // 协会等级：1：省级，2：市级
    //   associationUserId: '', // 律协用户id
    //   associationId: '', // 律协id
    //   username: '', // 律协用户名称
    //   phone: '', // 手机号
    //   email: '', // 邮箱
    //   position: '', // 职位
    //   department: '', // 部门
    // },
  }
