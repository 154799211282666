import { TOKEN_INFO, USER_INFO } from '@/constants'
import { filterRoutes, routes } from '@/router'
import { StoreTypesEnum, useStore } from '@/store'
import { cache } from '@/utils'
import { nextTick } from 'vue'
import { RouteRecordRaw } from 'vue-router'
export const useLoginInfo = () => {
  const store = useStore()
  const setLoginInfo = ({ userInfo, tokenInfo }: { userInfo: UserInfo; tokenInfo: TokenInfo }) => {
    // TODO: 需要移除这两行并把所有Storage.get(TOKEN_INFO)都改成getUserInfo
    cache.set(TOKEN_INFO, tokenInfo, { expire: tokenInfo.expireTime })
    cache.set(USER_INFO, userInfo, { expire: tokenInfo.expireTime })
    store.commit(StoreTypesEnum.setUserInfo, userInfo)
    nextTick(() => {
      const menus = filterRoutes(routes[0].children as RouteRecordRaw[])
      store.commit('app/setMenus', menus)
    })
  }
  return {
    setLoginInfo,
  }
}
