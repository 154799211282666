/**
 * @description 数据采集对象
 */
export const REPORT_OBJECT = {
  1: '律协',
  2: '律所',
};

/**
 * @description 发布对象全省律所
 */
export const REPORT_OBJECT_FIRM = [
  {
    value: 'PL_BUSINESS_STATISTICS_DATA_COLLECTION_1',
    label: '律师业务统计表（表一）',
    reportType: 12,
    homeIcon: 'icon-a-lvshiyewucaijibiaoer',
    icon: 'icon-shujuzhongxin-lvshiyewucaijibiaoer',
  },
  {
    value: 'PL_BUSINESS_STATISTICS_DATA_COLLECTION_2',
    label: '律师业务统计表（表二）',
    reportType: 13,
    homeIcon: 'icon-a-lvshiyewucaijibiaoyi',
    icon: 'icon-shujuzhongxin-lvshiyewucaijibiaoyi',
  },
  {
    value: 'PL_POLITICS_DATA_COLLECTION',
    label: '律师参政议政统计表',
    reportType: 14,
    homeIcon: 'icon-lvshicanzhengyizheng',
    icon: 'icon-shujuzhongxin-lvshicanzhengyizheng',
  },
  {
    value: 'PL_SERVICE_DATA_COLLECTION',
    label: '律师公益法律服务统计表',
    reportType: 11,
    homeIcon: 'icon-gongyifalvfuwu',
    icon: 'icon-shujuzhongxin-gongyifalvfuwu',
  },
];

/**
 * @description 数据提交方式（单条数据提交）表三、表六
 */
export const REPORT_SUBMIT_SINGLE = [1, 3, 6];

/**
 * @description 表单拓展数据（附件、备注）的表头
 */
export const REPORT_EXTEND_COLUMNS = [
  {
    title: '单位名称',
    dataIndex: 'recipientName',
    width: 200,
  },
  {
    title: '备注',
    dataIndex: 'remark',
    ellipsis: true,
  },
  {
    title: '附件',
    dataIndex: 'fileList',
  },
  {
    title: '操作',
    dataIndex: 'action',
    width: 150,
  },
];

// export const COLLECTION_STATUS = {
//   1: '已读',
//   2: '未读'
// };
