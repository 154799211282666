import { AppState } from './state';

export const mutations = {
  setRoutes: (state: AppState, routes): void => {
    // 设置动态路由
    // state.menus = routes;
  },
  setMenus: (state: AppState, routes): void => {
    // 设置动态路由
    state.menus = routes;
  },
  setKeepAliveComponents: (state: AppState, compNames): void => {
    // 设置需要缓存的组件
    state.keepAliveComponents = compNames;
  }
};
