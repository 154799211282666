<template>
  <svg
    class="zf-iconfont"
    aria-hidden="true"
    :style="{ width: width ? `${width}px` : `${fontSize}px`, height: height ? `${height}px` : `${fontSize}px` }"
  >
    <use :xlink:href="icon"></use>
  </svg>
</template>

<script>
export default {
  name: 'SvgIcon',
  props: {
    width: Number,
    height: Number,
    fontSize: Number,
    iconClass: {
      type: String,
      required: true
    }
  },
  setup() {
    return {};
  },
  computed: {
    icon() {
      return `#${this.iconClass}`;
    }
  }
};
</script>

<style lang="less" scoped>
.zf-iconfont {
  width: 1.2em;
  height: 1.2em;
  outline: none;
  overflow: hidden;
  vertical-align: middle;
  fill: currentColor;
  cursor: pointer;
}
</style>
