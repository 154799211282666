/**
 * @description 律师事务所统计表（8）
 */
export const REPORT_EIGHT_COLUMNS = [
  {
    title: '单位',
    dataIndex: 'associationName',
    width: 55,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '律所总数合计',
    dataIndex: 'firmTotal',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '组织形式-合伙所',
    dataIndex: 'partnershipFirm',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '组织形式-普通合伙所',
    dataIndex: 'generalPartnershipFirm',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '组织形式-特殊的普通合伙所',
    dataIndex: 'specialPartnershipFirm',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '组织形式-国资所',
    dataIndex: 'stateOwnedAssetsFirm',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '组织形式-个人所',
    dataIndex: 'personalFirm',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '组织形式-律师事务所分所总数',
    dataIndex: 'firmBranchTotal',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '组织形式-合伙人3人(含)至10人',
    dataIndex: 'partnerThree',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '组织形式-合伙人10人(含)至50人',
    dataIndex: 'partnerTen',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '组织形式-合伙人50人(含)至100人',
    dataIndex: 'partnerFifty',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '组织形式-合伙人100人(含)以上',
    dataIndex: 'partnerOneHundred',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '组织形式-律师10人以下',
    dataIndex: 'lawyerTenBelow',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '组织形式-律师10人(含)至30人',
    dataIndex: 'lawyerTen',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '组织形式-律师30人(含)至50人',
    dataIndex: 'lawyerThirty',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '组织形式-律师50人(含)至100人',
    dataIndex: 'lawyerFifty',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '组织形式-律师100人(含)以上',
    dataIndex: 'lawyerOneHundred',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '奖惩情况-受（含）以上表彰奖的律所',
    dataIndex: 'recognitionFirm',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '奖惩情况-受行业处分的律所',
    dataIndex: 'disciplinaryFirm',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '奖惩情况-训诫',
    dataIndex: 'admonition',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '奖惩情况-警告',
    dataIndex: 'warn',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '奖惩情况-通报批评',
    dataIndex: 'announceCriticism',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '奖惩情况-公开谴责',
    dataIndex: 'publicCondemnation',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '奖惩情况-中止会员权利一个月以上一年以下',
    dataIndex: 'rightSuspend',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '奖惩情况-取消会员资格',
    dataIndex: 'cancelMembership',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '奖惩情况-受行政处罚的律所',
    dataIndex: 'administrativePenalties',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '奖惩情况-警告',
    dataIndex: 'xzAdmonition',
    width: 31,
    ellipsis: true,
    align: 'center'
  },

  // {
  //   title: '年龄结构-50-64岁',
  //   dataIndex: 'fiftyAge',
  //   width: 31,
  //   ellipsis: true,
  //   align: 'center'
  // },
  // {
  //   title: '年龄结构-65岁以上',
  //   dataIndex: 'sixtyFiveAge',
  //   width: 31,
  //   ellipsis: true,
  //   align: 'center'
  // },
  // {
  //   title: '性别-女律师',
  //   dataIndex: 'femaleLawyer',
  //   width: 31,
  //   ellipsis: true,
  //   align: 'center'
  // },
  // {
  //   title: '少数民族律师',
  //   dataIndex: 'minorityLawyers',
  //   width: 31,
  //   ellipsis: true,
  //   align: 'center'
  // },
  // {
  //   title: '政治面貌-中共党员',
  //   dataIndex: 'cpcMember',
  //   width: 31,
  //   ellipsis: true,
  //   align: 'center'
  // },
  // {
  //   title: '政治面貌-民主党派',
  //   dataIndex: 'democraticParties',
  //   width: 31,
  //   ellipsis: true,
  //   align: 'center'
  // },
  // {
  //   title: '政治面貌-无党派人士',
  //   dataIndex: 'nonpartisan',
  //   width: 31,
  //   ellipsis: true,
  //   align: 'center'
  // },
  // {
  //   title: '奖惩情况-受（含）以上表彰奖的律师',
  //   dataIndex: 'recognitionLawyer',
  //   width: 31,
  //   ellipsis: true,
  //   align: 'center'
  // },
  // {
  //   title: '奖惩情况-受行业处分的律师',
  //   dataIndex: 'disciplinaryLawyer',
  //   width: 31,
  //   ellipsis: true,
  //   align: 'center'
  // },
  // {
  //   title: '奖惩情况-训诫',
  //   dataIndex: 'admonition',
  //   width: 31,
  //   ellipsis: true,
  //   align: 'center'
  // },
  // {
  //   title: '奖惩情况-警告',
  //   dataIndex: 'warn',
  //   width: 31,
  //   ellipsis: true,
  //   align: 'center'
  // },
  // {
  //   title: '奖惩情况-通报批评',
  //   dataIndex: 'announceCriticism',
  //   width: 31,
  //   ellipsis: true,
  //   align: 'center'
  // },
  // {
  //   title: '奖惩情况-公开谴责',
  //   dataIndex: 'publicCondemnation',
  //   width: 31,
  //   ellipsis: true,
  //   align: 'center'
  // },
  // {
  //   title: '奖惩情况-中止会员权利一个月以上一年以下',
  //   dataIndex: 'rightSuspend',
  //   width: 31,
  //   ellipsis: true,
  //   align: 'center'
  // },
  // {
  //   title: '奖惩情况-取消会员资格',
  //   dataIndex: 'cancelMembership',
  //   width: 31,
  //   ellipsis: true,
  //   align: 'center'
  // },
  // {
  //   title: '奖惩情况-受行政处罚的律师',
  //   dataIndex: 'administrativePenalties',
  //   width: 31,
  //   ellipsis: true,
  //   align: 'center'
  // },
  // {
  //   title: '奖惩情况-警告',
  //   dataIndex: 'xzAdmonition',
  //   width: 31,
  //   ellipsis: true,
  //   align: 'center'
  // },
  {
    title: '奖惩情况-罚款',
    dataIndex: 'fine',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '奖惩情况-停业整顿一个月以上六个月以下',
    dataIndex: 'stopPracticeOne',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '奖惩情况-吊销执业证书',
    dataIndex: 'revokeCertificate',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '当事人投诉的案件数',
    dataIndex: 'caseNum',
    width: 31,
    ellipsis: true,
    align: 'center'
  },

  {
    title: '纳税情况-纳税金额合计',
    dataIndex: 'taxLiabilityTotal',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '纳税情况-一般纳税人',
    dataIndex: 'generalTaxpayer',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '纳税情况-缴纳增值税',
    dataIndex: 'addedValueTax',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '纳税情况-缴纳合伙人所得税',
    dataIndex: 'incomeTax',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '纳税情况-代扣代缴非合伙人律师所得税',
    dataIndex: 'withholdTax',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '纳税情况-其他',
    dataIndex: 'otherTax',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '纳税情况-小规模纳税人',
    dataIndex: 'smallScaleTaxpayer',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '纳税情况-小规模纳税人',
    dataIndex: 'smallAddedValueTax',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '纳税情况-缴纳合伙人所得税',
    dataIndex: 'smallIncomeTax',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '纳税情况-代扣代缴非合伙人律师所得税',
    dataIndex: 'smallWithholdTax',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '纳税情况-其他',
    dataIndex: 'smallOtherTax',
    width: 31,
    ellipsis: true,
    align: 'center'
  }
];
