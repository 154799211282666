/**
 * @description 表格类型
 */
export enum ReportTypeEnum {
  REPORT_ONE = 1,
  REPORT_TWO = 2,
  REPORT_THREE = 3,
  REPORT_FOUR = 4,
  REPORT_FIVE = 5,
  REPORT_SIX = 6,
  REPORT_SEVEN = 7,
  REPORT_EIGHT = 8,
  REPORT_NINE = 9,
  REPORT_TEN = 10,
  REPORT_ELEVEN = 11,
  REPORT_TWELVE = 12,
  REPORT_THIRTEEN = 13,
  REPORT_FOURTEEN = 14,
  REPORT_FIFTEEN = 15
}

/**
 * @description 表四单位
 */
export enum ReportFourUnit {
  LAW_FIRM = 1,
  LAWYER = 2,
  TOTAL_MONTH = 3,
  TOTAL_CURRENT = 4
}

/**
 * @description 表5单位
 */
export enum ReportFiveUnit {
  MPNTH = 1, // 当月
  TOTAL_CURRENT = 2 // 目前累计
}

/**
 * @description 表1类型
 */
export enum ReportOneType {
  TRANSFER_IN = 1, // 转入党员
  TRANSFER_OUT = 2, // 转出党员
  ADD = 3 // 新增党员
}

export enum CollectionStatusEnum {
  DOING = 1, // 采集中
  END = 2 // 采集结束
}

export const CollectionStatusEnumMap = {
  [CollectionStatusEnum.DOING]: { desc: '采集中', color: '#f79160' },
  [CollectionStatusEnum.END]: { desc: '采集结束', color: '#5BA6FF' }
}

