/**
 * @description 全省律师参政议政统计表采集（14）
 */
export const REPORT_FOURTEEN_COLUMNS = [
  {
    title: '单位',
    dataIndex: 'unitName',
    width: 70,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '人大代表-合计',
    dataIndex: 'deputiesTotal',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '人大代表-全国人大代表',
    dataIndex: 'nationalDeputies',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '人大代表-省级人大代表',
    dataIndex: 'provincialDeputies',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '人大代表-设区的市级人大代表',
    dataIndex: 'cityDeputies',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '人大代表-县级人大代表',
    dataIndex: 'countyDeputies',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '人大代表-中共党员',
    dataIndex: 'cpcMember',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '人大代表-民主党派',
    dataIndex: 'democraticParties',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '人大代表-无党派人士',
    dataIndex: 'nonpartisan',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '人大代表-提出建议',
    dataIndex: 'propose',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '人大代表-参与立法、修法',
    dataIndex: 'participateLegislation',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '人大代表-参与执法检查',
    dataIndex: 'participateEnforcement',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '人大代表-参与调研',
    dataIndex: 'participateResearch',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '人大代表-其他',
    dataIndex: 'participateOther',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '政协委员-合计',
    dataIndex: 'cppccTotal',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '政协委员-全国政协委员会',
    dataIndex: 'nationalCppcc',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '政协委员-省级政协委员',
    dataIndex: 'provincialCppcc',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '政协委员-设区的市级政协委员',
    dataIndex: 'cityCppcc',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '政协委员-县级政协委员',
    dataIndex: 'countyCppcc',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '政协委员-中共党员',
    dataIndex: 'cppccCpcMember',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '政协委员-民主党派',
    dataIndex: 'cppccDemocraticParties',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '政协委员-无党派人士',
    dataIndex: 'cppccNonpartisan',
    width: 31,
    ellipsis: true,
    align: 'center'
  },

  {
    title: '政协委员-提出建议',
    dataIndex: 'cppccPropose',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '政协委员-参与立法、修法',
    dataIndex: 'cppccParticipateLegislation',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '政协委员-参与协商座谈',
    dataIndex: 'cppccParticipateDiscussion',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '政协委员-参与调研',
    dataIndex: 'cppccParticipateResearch',
    width: 31,
    ellipsis: true,
    align: 'center'
  },

  {
    title: '政协委员-其他',
    dataIndex: 'cppccParticipateOther',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '担任党代会代表-合计',
    dataIndex: 'partyCongressDeputiesTotal',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '担任党代会代表-全国党代会代表',
    dataIndex: 'nationalPartyCongressDeputies',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '担任党代会代表-省级党代会代表',
    dataIndex: 'provincialPartyCongressDeputies',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '担任党代会代表-设区的市级党代会代表',
    dataIndex: 'cityPartyCongressDeputies',
    width: 31,
    ellipsis: true,
    align: 'center'
  },

  {
    title: '担任党代会代表-县级党代会代表',
    dataIndex: 'countyPartyCongressDeputies',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '担任党代会代表-担任法官遴选委员会委员的律师人数',
    dataIndex: 'judgeLawyer',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '担任党代会代表-担任检察官遴选委员会委员的的律师人数',
    dataIndex: 'prosecutorLawyer',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '担任党代会代表-担任人民法院特约监督员的律师人数',
    dataIndex: 'courtSupervisorLawyer',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '党代会代表-担任人民检察院特约监督员、特约检察的律师',
    dataIndex: 'procuratorateSupervisorLawyer',
    width: 31,
    ellipsis: true,
    align: 'center'
  }
];
