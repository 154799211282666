
import { ref, defineComponent, onErrorCaptured } from 'vue';
export default defineComponent({
  name: 'SuspenseWithError',
  setup() {
    const error = ref<any>(null);
    onErrorCaptured(() => {
      error.value = '(⊙o⊙)…出了点小问题。';
      return true;
    });
    return { error };
  }
});
