import { Storage } from '@/utils'
import {
	NoticeTypeEnum,
	NoticePageEnum,
	DataOperationTypeEnum,
	StatisticsPageEnum,
	ExceptionEnum,
} from '@/enums'
import { isInWujie, WujieBusEnum } from '@common/utils'
import { router } from '@/router'
// import { isInWujie } from '@common/utils'
// console.log('🚀 ~ isInWujie', isInWujie())
/**
 * 重定向到登录页
 */
export const redirectToLogin = (
	url: string = router.currentRoute.value.fullPath,
) => {
	// 如果处于微前端环境下, 则把重定向交给主应用去做
	if (isInWujie()) {
		window.$wujie?.bus.$emit(WujieBusEnum.LOGIN, 'lx')
	} else {
		Storage.clear()
		// TODO: 重置store
		router.replace(`/login?${url ? 'redirectUrl=' + url : ''}`)
	}
}

/**
 * 重定向到首页
 */
export const redirectToHome = () => {
	router.replace('/home')
}

/**
 * 导航到异常页
 */
export const navigateToException = (
	code: ExceptionEnum,
	{ replace }: { replace?: boolean } = { replace: true },
) => {
	replace
		? router.replace(`/exception/${code}`)
		: router.push(`/exception/${code}`)
}

/**
 * @description 跳转到通告添加或编辑页
 */
export const toNoticeOperation = (bulletinId?: string) => {
	router.push({
		name: 'NoticeSend',
		params: {
			page: NoticePageEnum.PAGE_OPERATION,
		},
		query: {
			bulletinId,
		},
	})
}

/**
 * @description 跳转到通知列表页
 * @param type 通知类别 ’send‘： 发送通知；’receive‘：接收通知
 */
export const toNoticeList = (
	type: string | null = NoticeTypeEnum.NOTICE_SEND,
) => {
	if (type === NoticeTypeEnum.NOTICE_SEND) {
		router.push({
			name: 'NoticeSend',
		})
	} else {
		router.push({
			name: 'NoticeReceive',
		})
	}
}

/**
 * @description 跳转到通知查看页
 * @param type 通知类别 ’send‘： 发送通知；’receive‘：接收通知
 */
export const toNoticeLook = (
	type: string | null = NoticeTypeEnum.NOTICE_SEND,
	bulletinId: string,
) => {
	if (type === NoticeTypeEnum.NOTICE_SEND) {
		router.push({
			name: 'NoticeSend',
			params: {
				page: NoticePageEnum.PAGE_LOOK,
			},
			query: {
				bulletinId,
			},
		})
	} else {
		router.push({
			name: 'NoticeReceive',
			params: {
				page: NoticePageEnum.PAGE_LOOK,
			},
			query: {
				bulletinId,
			},
		})
	}
}

/**
 * @description 跳转到某个统计表格的列表页（时间轴）
 * @param type 数据采集类别 ’collection‘： 采集；’fill‘：填报
 */
export const toReportTimeList = (
	type: string | null = DataOperationTypeEnum.OPERATION_COLLECTION,
	reportType?: number,
) => {
	if (type === DataOperationTypeEnum.OPERATION_COLLECTION) {
		router.push({
			name: 'StatisticsCollection',
			params: {
				page: StatisticsPageEnum.PAGE_TABLE_TIME_LIST,
			},
			query: {
				reportType,
			},
		})
	} else if (type === DataOperationTypeEnum.OPERATION_FILL) {
		router.push({
			name: 'StatisticsSubmit',
			params: {
				page: StatisticsPageEnum.PAGE_TABLE_TIME_LIST,
			},
			query: {
				reportType,
			},
		})
	} else {
		router.push({
			name: 'StatisticsLook',
			params: {
				page: StatisticsPageEnum.PAGE_TABLE_TIME_LIST,
			},
			query: {
				reportType,
			},
		})
	}
}

/**
 * @description 跳转数据采集表的新增/编辑页（发布数据采集表）
 * @param reportType 表类型（14种）
 */
export const toCollectionForm = (reportType?: number) => {
	router.push({
		name: 'StatisticsCollection',
		params: {
			page: StatisticsPageEnum.PAGE_FORM,
		},
		query: {
			reportType,
		},
	})
}

/**
 * @description 跳转到查看表页面（数据采集）
 * @param reportType 表类型（14种）
 */

export const toCollectionLook = (
	reportType?: number,
	id?: string | number,
	unitId?: string | number,
) => {
	router.push({
		name: 'StatisticsCollection',
		params: {
			page: StatisticsPageEnum.PAGE_TABLE_LOOK_COLLECTION,
		},
		query: {
			reportType,
			id,
			unitId,
		},
	})
}

/**
 * @description 跳转到查看表页面（数据采集）
 * @param reportType 表类型（14种）
 */

export const toCollectionLookhn = (
	reportType?: number,
	id?: string | number,
	unitId?: string | number,
) => {
	router.push({
		name: 'StatisticsCollectionHn',
		params: {
			page: StatisticsPageEnum.PAGE_TABLE_LOOK_COLLECTION,
		},
		query: {
			reportType,
			id,
			unitId,
		},
	})
}

/**
 * @description 跳转到查看表页面（省级查看）
 * @param reportType 表类型（14种）
 */
export const toCityLook = (reportType?: number, id?: string | number) => {
	router.push({
		name: 'StatisticsLook',
		params: {
			page: StatisticsPageEnum.PAGE_TABLE_LOOK_COLLECTION,
		},
		query: {
			reportType,
			id,
		},
	})
}
/**
 * @description 跳转到查看表页面（数据报送）
 * @param reportType 表类型（14种）
 */
export const toSubmitLook = (reportType?: number, id?: string | number) => {
	router.push({
		name: 'StatisticsSubmit',
		params: {
			page: StatisticsPageEnum.PAGE_TABLE_LOOK_SUBMIT,
		},
		query: {
			reportType,
			id,
		},
	})
}

/**
 * @description 跳转到查看表页面（数据报送）
 * @param reportType 表类型（14种）
 */
export const toSubmitLookHn = (reportType?: number, id?: string | number) => {
	router.push({
		name: 'StatisticsSubmitHn',
		params: {
			page: StatisticsPageEnum.PAGE_TABLE_LOOK_SUBMIT,
		},
		query: {
			reportType,
			id,
		},
	})
}

/**
 * @description 跳转到查看表页面（省级查看具体某单位）
 * @param reportType 表类型（14种）
 */
export const toCollectionLookCom = (
	reportType?: number,
	id?: string | number,
) => {
	router.push({
		name: 'StatisticsCollection',
		params: {
			page: StatisticsPageEnum.PAGE_TABLE_LOOK_SUBMIT,
		},
		query: {
			reportType,
			id,
		},
	})
}

/**
 * @description 跳转到查看表页面（市级查看具体某单位）
 * @param reportType 表类型（14种）
 */
export const toCityLookCom = (reportType?: number, id?: string | number) => {
	router.push({
		name: 'StatisticsLook',
		params: {
			page: StatisticsPageEnum.PAGE_TABLE_LOOK_SUBMIT,
		},
		query: {
			reportType,
			id,
		},
	})
}

/**
 * @description 跳转到编辑页面（数据报送）
 * @param reportType 表类型（14种）
 */
export const toSubmitEdit = (reportType?: number, id?: string | number) => {
	router.push({
		name: 'StatisticsSubmit',
		params: {
			page: StatisticsPageEnum.PAGE_FORM,
		},
		query: {
			reportType,
			id,
		},
	})
}

/**
 * @description 跳转到编辑页面（数据报送）
 * @param reportType 表类型（14种）
 */
export const toSubmitEditHn = (reportType?: number, id?: string | number) => {
	router.push({
		name: 'StatisticsSubmitHn',
		params: {
			page: StatisticsPageEnum.PAGE_FORM,
		},
		query: {
			reportType,
			id,
		},
	})
}

/**
 * @description 跳转到单位列表页(跳转到自己发布的采集的详情页,可以查看所有数据)
 * @param reportType 表类型（14种）
 */
export const toCollectionDetailListHn = (
	reportType?: number,
	id?: string | number,
) => {
	router.push({
		name: 'StatisticsCollectionHn',
		params: {
			page: StatisticsPageEnum.PAGE_TABLE_DETAIL_LIST,
		},
		query: {
			reportType,
			id,
		},
	})
}

/**
 * @description 跳转到单位列表页(跳转到自己发布的采集的详情页,可以查看所有数据)
 * @param reportType 表类型（14种）
 */
export const toCollectionDetailList = (
	reportType?: number,
	id?: string | number,
) => {
	router.push({
		name: 'StatisticsCollection',
		params: {
			page: StatisticsPageEnum.PAGE_TABLE_DETAIL_LIST,
		},
		query: {
			reportType,
			id,
		},
	})
}

/**
 * @description 市级律所跳转到单位列表页(市级只能查看本市律所数据)
 * @param reportType 表类型（14种）
 */
export const toCityDetailList = (reportType?: number, id?: string | number) => {
	router.push({
		name: 'StatisticsLook',
		params: {
			page: StatisticsPageEnum.PAGE_TABLE_DETAIL_LIST,
		},
		query: {
			reportType,
			id,
		},
	})
}
