import { isProd } from '@/utils'

export const PAGE_SIZE_OPTIONS = isProd()
	? ['8', '10', '20', '30', '50', '100']
	: ['8', '1', '10', '20', '30', '50', '100']

export const WHETHER_OPTIONS = [
	{
		value: 1,
		label: '是',
	},
	{
		value: 2,
		label: '否',
	},
]

export const APP_TITLE = ''

/**
 * 河南省自定义采集枚举 axios 前缀
 */
export const HNURLPREFIX = [
	'henan-zz',
	'henan-ly',
	'henan-kf',
	'henan-pds',
	'henan-jz',
	'henan-hb',
	'henan-xx',
	'henan-ay',
	'henan-py',
	'henan-xc',
	'henan-lh',
	'henan-smx',
	'henan-ny',
	'henan-sq',
	'henan-xy',
	'henan-zk',
	'henan-zmd',
	'henan-jy',
]
/**
 * 河南省自定义采集枚举 axios 全球地址
 */
export const HNAXIOSURL = {
	'henan-zz': 'https://zz.hnlawyer.org',
	'henan-ly': 'https://ly.hnlawyer.org',
	'henan-kf': 'https://kf.hnlawyer.org',
	'henan-pds': 'https://pds.hnlawyer.org',
	'henan-jz': 'https://jz.hnlawyer.org',
	'henan-hb': 'https://hb.hnlawyer.org',
	'henan-xx': 'https://xx.hnlawyer.org',
	'henan-ay': 'https://ay.hnlawyer.org',
	'henan-py': 'https://py.hnlawyer.org',
	'henan-xc': 'https://xc.hnlawyer.org',
	'henan-lh': 'https://lh.hnlawyer.org',
	'henan-smx': 'https://smx.hnlawyer.org',
	'henan-ny': 'https://ny.hnlawyer.org',
	'henan-sq': 'https://sq.hnlawyer.org',
	'henan-xy': 'https://xy.hnlawyer.org',
	'henan-zk': 'https://zk.hnlawyer.org',
	'henan-zmd': 'https://zmd.hnlawyer.org',
	'henan-jy': 'https://jy.hnlawyer.org',
}
