
import * as ExcelJs from 'exceljs'
import { saveAs } from 'file-saver'
/**
 * 获取文件扩展名
 * @param fileName 文件名称，可以是http的形式(仅限于我们系统上传的文件)
 */
export const getFileExt = (fileName: string, upperCase?: boolean) => {
  const index = fileName.lastIndexOf('.');
  const ext = fileName.slice(index + 1); // 获取后缀
  return upperCase ? ext.toUpperCase() : ext;
};

/**
 * 根据文件后缀名获取图标
 * @param {String} extend 扩展名
 * @returns {String}
 */
export const getFileIcon = extend => {
  const maps = {
    '.rar': 'fc-yasuowenjian-new',
    '.zip': 'fc-yasuowenjian-new',
    '.tar': 'fc-yasuowenjian-new',
    '.gz': 'fc-yasuowenjian-new',
    '.bz2': 'fc-yasuowenjian-new',
    '.doc': 'fc-word-new',
    '.docx': 'fc-word-new',
    '.xlsx': 'fc-excle-new',
    '.xls': 'fc-excle-new',
    '.pdf': 'fc-pdf-new',
    '.mp3': 'fc-music-new',
    '.ppt': 'fc-ppt-new',
    '.pptx': 'fc-ppt-new',
    '.mp4': 'fc-shipin-new',
    '.avi': 'fc-shipin-new',
    '.rmvb': 'fc-shipin-new',
    '.wmv': 'fc-shipin-new',
    '.flv': 'fc-shipin-new',
    '.swf': 'fc-shipin-new',
    '.rm': 'fc-shipin-new',
    '.qsv': 'fc-shipin-new',
    '.txt': 'fc-txt-new',
    '.jpg': 'fc-picture-new',
    '.png': 'fc-picture-new',
    '.jpeg': 'fc-picture-new',
    '.gif': 'fc-picture-new',
    '.ico': 'fc-picture-new',
    '.bmp': 'fc-picture-new',
    '.unknow': 'fc-weizhileixing-new'
  };
  return maps[extend.toLowerCase()] ? maps[extend.toLowerCase()] : maps['.unknow'];
};

/**
 * 通过blob下载文件
 * @param {String} fileName 文件名称
 * @param {any} data 二进制流
 * @param {Object} blobOptions blobOptions
 * @returns
 */
export const dowloadBlobFile = (fileName, data, blobOptions = {}) => {
  if (!data) {
    return;
  }

  const blob = new Blob([data], blobOptions);

  const elink = document.createElement('a');
  const navigator: any = {};
  if ('download' in elink) {
    // 非IE下载
    elink.download = fileName;
    elink.style.display = 'none';
    elink.href = URL.createObjectURL(blob);
    document.body.appendChild(elink);
    elink.click();
    URL.revokeObjectURL(elink.href); // 释放URL 对象
    document.body.removeChild(elink);
  } else {
    // IE10+下载
    navigator.msSaveBlob(blob, fileName);
  }
};

/**
 * 为防止 后台返回错误的code无法拦截 所以需要将blob转换为json 解析
 */
export const blobToText = blob => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsText(new Blob([blob]), 'utf-8');
    fileReader.onload = (res: any) => {
      try {
        const { result } = res.target; // 得到字符串
        const data = JSON.parse(result);

        if (data) {
          resolve(data);
        } else {
          reject();
        }
      } catch (e) {
        // TODO handle the exception
        reject(e);
      }
    };
  });
};

// 按二进制格式读取文件
export const readFile = (file: File): Promise<ArrayBuffer> => {
  return new Promise(resolve => {
    let reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = ev => {
      resolve(ev.target?.result as ArrayBuffer);
    };
  });
}
export const exportExcel = (columns: any, dataSource: any, name: string) => {
  // 创建工作簿
  const workbook = new ExcelJs.Workbook()
  // // 添加sheet
  const worksheet = workbook.addWorksheet('demo sheet')
  // // 设置 sheet 的默认行高
  worksheet.properties.defaultRowHeight = 20
  worksheet.properties.defaultColWidth = 30
  // workbook.defaultColWidth = 400
  // // 设置列
  worksheet.columns = generateHeaders(columns)
  // // 添加行
  worksheet.addRows(dataSource)
  // // // 导出excel
  workbook.xlsx.writeBuffer().then((res) => {
    saveAs(new Blob([res]), `${name}.xlsx`)
  })
}
export const generateHeaders = (columns) => {
  return columns?.map(({ title, dataIndex }) => ({ header: title, key: dataIndex }))
}