// 自动导入全局组件
// 引入lodash
import { upperFirst, camelCase } from 'lodash-es' // 首字线大写
// 把 /components/global/ 下的所有 vue 组件 require 进来
// path: 要引入的组件所在相对路径（相对于当前文件）
// deep: 是否检索子文件夹
// matchFile: 匹配的文件名称
// require.context(path, deep, matchFile)
const requireComponent = require['context']('./', true, /\.vue$/)
const globalComponent: { componentName: string; componentConfig: any }[] = [] // 存放组件配置
// 遍历 require 进来的组件
requireComponent.keys().forEach((fileName) => {
  const componentConfig = requireComponent(fileName)
  const componentName = upperFirst(
    camelCase(
      fileName
        .slice(fileName.lastIndexOf('/')) // 只要文件名
        .replace(/^\.\/_/, '')
        .replace(/\.\w+$/, ''),
    ),
  )
  globalComponent.push({ componentName, componentConfig })
})
export default globalComponent
