import { LocalStorageKeys, TOKEN_INFO } from '@/constants'
import { RoleEnum } from '@/enums'
import { IStore } from '@/store'
import { cache } from './cache'

export const getLocalGlobalState = () =>
	cache.get<IStore>(LocalStorageKeys.GLOBAL_STATE)

export const getUserInfo = () => getLocalGlobalState()?.user.userInfo

export const getTokenInfo = () => cache.get<TokenInfo>(TOKEN_INFO)

export const getUserNickname = () =>
	getLocalGlobalState()?.user.userInfo?.associationName + '律协'

export const getUserRole = () =>
	getUserInfo()?.associationLevel as RoleEnum | undefined
