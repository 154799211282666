import { App } from 'vue';
import globalComponent from '@/components/global';

/**
 * 全局注册自定义组件
 * @param app
 */
export function setupCustomComponents(app: App) {
  globalComponent.forEach(({ componentName, componentConfig }) => {
    app.component(componentName, componentConfig.default || componentConfig);
  });
}
