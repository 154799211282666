/**
 * @description 律师公益法律服务统计表（11）
 */
export const REPORT_ELEVEN_COLUMNS = [
  {
    title: '单位',
    dataIndex: 'unitName',
    width: 105,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '公益法律服务件数合计',
    dataIndex: 'benefitLegalTotal',
    width: 50,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '办理法律援助案件合计',
    dataIndex: 'benefitLegalCase',
    width: 50,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '参与信访接待和处理的律师人数',
    dataIndex: 'receptionLawyer',
    width: 50,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '参与信访接待和处理的案件数合计',
    dataIndex: 'receptionCase',
    width: 50,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '律师调解-合计',
    dataIndex: 'lawyerMediationTotal',
    width: 50,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '律师调解-人民法院律师调解工作室调解案件',
    dataIndex: 'courtMediation',
    width: 50,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '律师调解-律师协会律师调解中心调解案件',
    dataIndex: 'associationMediation',
    width: 50,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '律师调解-律师事务所设立的调解工作室调解的案件',
    dataIndex: 'studioMediation',
    width: 50,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '村居法律顾问-担任村居法律顾问的律师人数',
    dataIndex: 'villageLawyer',
    width: 50,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '村居法律顾问-建立村居法律顾问的微信群个数',
    dataIndex: 'villageWx',
    width: 50,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '村居法律顾问-农村',
    dataIndex: 'ruralArea',
    width: 50,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '村居法律顾问-社区',
    dataIndex: 'community',
    width: 50,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '参与城管执法-驻队律师人数',
    dataIndex: 'teamLawyer',
    width: 50,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '参与城管执法-参与处置城管执法事件数',
    dataIndex: 'lawEnforcement',
    width: 50,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '为社会弱势群体提供免费法律服务-老年人',
    dataIndex: 'elderly',
    width: 50,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '为社会弱势群体提供免费法律服务-妇女',
    dataIndex: 'women',
    width: 50,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '为社会弱势群体提供免费法律服务-未成年人',
    dataIndex: 'minor',
    width: 50,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '为社会弱势群体提供免费法律服务-农民工',
    dataIndex: 'worker',
    width: 50,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '为社会弱势群体提供免费法律服务-残疾人',
    dataIndex: 'handicapped',
    width: 50,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '为社会弱势群体提供免费法律服务-其他',
    dataIndex: 'other',
    width: 50,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '为社会弱势群体提供免费法律服务-捐款捐赠',
    dataIndex: 'donationDonation',
    width: 50,
    ellipsis: true,
    align: 'center'
  }
];
