/**
 * @description 公职律师公司律师统计表（10）
 */
export const REPORT_TEN_COLUMNS = [
  {
    title: '单位',
    dataIndex: 'associationName',
    width: 63,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '开展公职律师事务所的单位-合计',
    dataIndex: 'unitTotal',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '开展公职律师事务所的单位-党委部门',
    dataIndex: 'partyNum',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '开展公职律师事务所的单位-政府部门',
    dataIndex: 'governmentNum',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '开展公职律师事务所的单位-人民团体',
    dataIndex: 'peopleOrgNum',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '开展公司律师工作的企业-合计',
    dataIndex: 'enterpriseTotal',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '开展公司律师工作的企业-国有企业',
    dataIndex: 'stateOwnedBusiness',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '公职律师工作情况-合计',
    dataIndex: 'workingConditionTotal',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '公职律师工作情况-对重大 决策、重大行政行为提供法律意见',
    dataIndex: 'legalOpinion',
    width: 63,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '公职律师工作情况-参与法律法规规章起草，党内法规草',
    dataIndex: 'participateDraft',
    width: 63,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '公职律师工作情况-参与合作项目的洽谈，协助起草，修改重要的文书',
    dataIndex: 'participateNegotiate',
    width: 87,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '公职律师工作情况-参与法律咨询，普法宣传，政府信息公开，信访接待',
    dataIndex: 'legalAdvice',
    width: 87,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '公职律师工作情况-办理行政复议，行政诉讼，行政处罚，行政裁决',
    dataIndex: 'administrativePunish',
    width: 63,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '公职律师工作情况-办理民商事诉讼，仲裁，调解案件',
    dataIndex: 'litigation',
    width: 63,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '公职律师工作情况-其他',
    dataIndex: 'other',
    width: 63,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '公司律师工作情况-合计',
    dataIndex: 'companyLawyerTotal',
    width: 63,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '公司律师工作情况-起草审查企业章程，董事会运行规则',
    dataIndex: 'draftConstitution',
    width: 63,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '公司律师工作情况-参与企业改制重组，并购上市，产权转让,和解及清算等重大经营决策事项，提出法律意',
    dataIndex: 'participateReorganization',
    width: 87,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '公司律师工作情况-参与外企对外谈判，磋商，起草，审查合同，协议及其他法律文书',
    dataIndex: 'foreignCompanyTalks',
    width: 63,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '公司律师工作情况-办理民商诉讼，仲裁，调解案件',
    dataIndex: 'handleCase',
    width: 63,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '公司律师工作情况-其他',
    dataIndex: 'companyLawyerOther',
    width: 47,
    ellipsis: true,
    align: 'center'
  }
];
