/**
 * @description 律师协会投诉受理查处工作月报表（4）
 */
export const REPORT_FOUR_COLUMNS = [
  {
    title: '单位',
    dataIndex: 'dataUnit',
    width: 79,
    ellipsis: true,
    align: 'center',
  },
  {
    title: '接待投诉数（件）',
    dataIndex: 'complaintNum',
    width: 31,
    ellipsis: true,
    align: 'center',
  },
  {
    title: '投诉方式-来信',
    dataIndex: 'letter',
    width: 31,
    ellipsis: true,
    align: 'center',
  },
  {
    title: '投诉方式-来访',
    dataIndex: 'comeVisit',
    width: 31,
    ellipsis: true,
    align: 'center',
  },
  {
    title: '投诉方式-来电',
    dataIndex: 'phoneCall',
    width: 31,
    ellipsis: true,
    align: 'center',
  },
  {
    title: '投诉方式-网上',
    dataIndex: 'internet',
    width: 31,
    ellipsis: true,
    align: 'center',
  },
  {
    title: '投诉方式-依职权主动调查（律协主动调查）',
    dataIndex: 'activeInvestigation',
    width: 31,
    ellipsis: true,
    align: 'center',
  },
  {
    title: '投诉方式-上级律协转办',
    dataIndex: 'associationTransfer',
    width: 31,
    ellipsis: true,
    align: 'center',
  },
  {
    title: '投诉方式-司法局转移',
    dataIndex: 'judicialBureauTransfer',
    width: 31,
    ellipsis: true,
    align: 'center',
  },
  {
    title: '投诉方式-公、检、法等单位来函等',
    dataIndex: 'otherLetter',
    width: 31,
    ellipsis: true,
    align: 'center',
  },
  {
    title: '接待投诉数（件）-重复投诉',
    dataIndex: 'repeatComplaint',
    width: 31,
    ellipsis: true,
    align: 'center',
  },
  {
    title: '接待投诉数（件）-不属受理范围',
    dataIndex: 'outRange',
    width: 31,
    ellipsis: true,
    align: 'center',
  },
  {
    title: '接待投诉数（件）-受理案件数（件）',
    dataIndex: 'acceptCaseNum',
    width: 31,
    ellipsis: true,
    align: 'center',
  },
  {
    title: '接待投诉数（件）-其他（再备注详细标明情况）',
    dataIndex: 'otherCaseNum',
    width: 31,
    ellipsis: true,
    align: 'center',
  },
  {
    title: '受理类型-利益冲突行为',
    dataIndex: 'interestConflict',
    width: 31,
    ellipsis: true,
    align: 'center',
  },
  {
    title: '受理类型-代理不尽责行为',
    dataIndex: 'irresponsible',
    width: 31,
    ellipsis: true,
    align: 'center',
  },
  {
    title: '受理类型-泄露秘密隐私行为',
    dataIndex: 'revealSecret',
    width: 31,
    ellipsis: true,
    align: 'center',
  },
  {
    title: '受理类型-违规收案、收费行为',
    dataIndex: 'violationToll',
    width: 31,
    ellipsis: true,
    align: 'center',
  },
  {
    title: '受理类型-妨碍司法公正行为',
    dataIndex: 'obstructionJustice',
    width: 31,
    ellipsis: true,
    align: 'center',
  },
  {
    title: '受理类型-以不正当方式影响依法办案行为',
    dataIndex: 'improperWay',
    width: 31,
    ellipsis: true,
    align: 'center',
  },
  {
    title: '受理类型-违反管理司法行政管理或行业管理行为',
    dataIndex: 'violationManagement',
    width: 31,
    ellipsis: true,
    align: 'center',
  },
  {
    title: '受理类型-不正当竞争行为',
    dataIndex: 'unfairCompetition',
    width: 31,
    ellipsis: true,
    align: 'center',
  },
  {
    title: '受理类型-其他行为',
    dataIndex: 'otherBehavior',
    width: 31,
    ellipsis: true,
    align: 'center',
  },
  {
    title: '小计-立案',
    dataIndex: 'caseNum',
    width: 31,
    ellipsis: true,
    align: 'center',
  },
  {
    title: '小计-不予立案',
    dataIndex: 'notCaseNum',
    width: 31,
    ellipsis: true,
    align: 'center',
  },
  {
    title: '处理情况-正在处理',
    dataIndex: 'processing',
    width: 31,
    ellipsis: true,
    align: 'center',
  },
  {
    title: '处理情况-做出处理措施-调解',
    dataIndex: 'mediate',
    width: 31,
    ellipsis: true,
    align: 'center',
  },
  {
    title: '处理情况-做出处理措施-移交司法',
    dataIndex: 'transferJustice',
    width: 31,
    ellipsis: true,
    align: 'center',
  },
  {
    title: '处理情况-做出处理措施-撤销案件',
    dataIndex: 'dismissCase',
    width: 31,
    ellipsis: true,
    align: 'center',
  },
  {
    title: '处理情况-做出处理措施-投诉不成立',
    dataIndex: 'complaintUnsubstantiated',
    width: 31,
    ellipsis: true,
    align: 'center',
  },
  {
    title: '处理情况-做出处理措施-规范执业或道德建议书',
    dataIndex: 'ethicalProposal',
    width: 31,
    ellipsis: true,
    align: 'center',
  },
  {
    title: '处理情况-做出处理措施-责令接受专门培训或限期整改',
    dataIndex: 'receiveTraining',
    width: 62,
    ellipsis: true,
    align: 'center',
  },
  {
    title: '处理情况-给予处分-训诫',
    dataIndex: 'admonition',
    width: 31,
    ellipsis: true,
    align: 'center',
  },
  {
    title: '处理情况-给予处分-警告',
    dataIndex: 'warn',
    width: 31,
    ellipsis: true,
    align: 'center',
  },
  {
    title: '处理情况-给予处分-通报批评',
    dataIndex: 'announceCriticism',
    width: 31,
    ellipsis: true,
    align: 'center',
  },
  {
    title: '处理情况-给予处分-公开谴责',
    dataIndex: 'publicCondemnation',
    width: 31,
    ellipsis: true,
    align: 'center',
  },
  {
    title: '处理情况-给予处分-中止会员权利',
    dataIndex: 'discontinueVip',
    width: 31,
    ellipsis: true,
    align: 'center',
  },
  {
    title: '处理情况-给予处分-取消会员资格',
    dataIndex: 'cancelVip',
    width: 31,
    ellipsis: true,
    align: 'center',
  },
  {
    title: '处理情况-小计-申请复查数',
    dataIndex: 'reviewNum',
    width: 31,
    ellipsis: true,
    align: 'center',
  },
  {
    title: '处理情况-小计-已结案数',
    dataIndex: 'closeCaseNum',
    width: 31,
    ellipsis: true,
    align: 'center',
  },
  {
    title: '处理情况-小计-未结案数',
    dataIndex: 'activeCaseNum',
    width: 31,
    ellipsis: true,
    align: 'center',
  },
];

/**
 * @description 律师协会投诉受理查处工作月报表单位
 */
export const REPORT_FOUR_COLUMNS_UNIT = {
  1: '律师事务所',
  2: '律师',
  3: '当月合计',
  4: '目前累计',
};
