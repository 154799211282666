export * from './loginInfo'
import { computed, WritableComputedRef } from 'vue'
import { useRoute } from 'vue-router'
/**
 * 子组件里需要改变父组件传过来的props时的快捷写法
 * @param props 组件的props
 * @param key 需要改变的props的key
 * @param emit defineEmit的返回值
 * @returns WritableComputedRef<T[K]
 */
export function useVModel<T extends object, K extends keyof T>(
  props: T,
  key: K,
  emit: (name: any, ...args: any[]) => void,
): WritableComputedRef<T[K]> {
  return computed({
    get: () => props[key],
    set: (val) => {
      emit(`update:${key.toString()}`, val)
    },
  })
}

export const useReportType = () => {
  const route = useRoute()
  return route.query.reportType ? Number(route.query.reportType) : undefined
}
