/**
 * 根据传入的reportType判断是否是固定的自定义采集(主要提现在获取采集列表, 发布采集, 填写采集, 查看填写详情等地方, 固定的自定义采集在这些场景下相比自定义采集和固定采集会有一些不同的行为比如需要调用不同的接口传不同的参数), 即原本的常规采集改成自定义采集后的采集,固定自定义采集在新增采集的时候不能选择模板。
 * 和isFixedCustomCollection的区别是isFixedCustomCollection判断的只是是否是原本的固定采集改成的自定义采集,而isFixedCollection判断的则是全部固定采集(包括部分没有转为自定义采集形式的固定采集)
 */
export const isFixedCustomCollection = (reportType?: number) =>
  !!reportType && [7, 8, 9, 10, 11, 12, 13, 14, 15].includes(reportType)

/**
 * 根据传入的reportType判断是否是自定义采集
 */
export const isCustomCollection = (reportType?: number) =>
  !reportType || reportType === -1 || isFixedCustomCollection(reportType)
/**
 * 判断是否需要限制只能填数字(固定的自定义采集和单行表只能填数字,多行表只能填文字)
 * @param isMultipleRow 是否是多行表格
 * @param isFixedCustomCollection 是否是固定的自定义采集
 */
export const isRestrictNumber = (isMultipleRow?: boolean, isFixedCustomCollection?: boolean) =>
  !!isFixedCustomCollection || !isMultipleRow
/**
 * 根据传入的reportType判断是否是固定采集, 和isFixedCustomCollection的区别是isFixedCustomCollection判断的只是是否是原本的固定采集改成的自定义采集,而isFixedCollection判断的则是全部固定采集(包括部分没有转为自定义采集形式的固定采集)
 */
export const isFixedCollection = (reportType?: number) => !!reportType && [1, 2, 3, 4, 5, 6].includes(reportType)
