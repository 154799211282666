/**
 * @description 填报党建数据
 */
export const FILL_BUILD_AUTH_OPTIONS = [
  {
    value: 'PARTY_MEMBER_CHANGE',
    label: '律师行业党员异动情况统计表',
    reportType: 1,
    homeIcon: 'icon-dangyuanyidongyuebao',
    icon: 'icon-shujuzhongxin-dangyuanyidongyuebao',
  },
  {
    value: 'PARTY_MEMBER_STANDARD',
    label: '律师行业党建规范化建设情况统计表',
    reportType: 2,
    homeIcon: 'icon-dangjianguifanhuayuebao',
    icon: 'icon-shujuzhongxin-dangjianguifanhuayuebao',
  },
  {
    value: 'LAWYER_INDUSTRY_PARTY_WORK',
    label: '律师行业党建工作统计表',
    reportType: 15,
    homeIcon: 'icon-lvshihangyedangjiangongzuotongjibiao',
    icon: 'icon-shujuzhongxin-lvshihangyedangjiangongzuotongjibiao',
  },
]

/**
 * @description 填报会员数据
 */
export const FILL_MEMBER_AUTH_OPTIONS = [
  {
    value: 'PUNISHED_DATA',
    label: '处分决定统计表',
    reportType: 3,
    homeIcon: 'icon-chufenjuedingyuebao',
    icon: 'icon-shujuzhongxin-chufenjuedingyuebao',
  },
  {
    value: 'ASSOCIATION_COMPLAINT_WORK',
    label: '律师协会投诉受理查处工作月报表',
    reportType: 4,
    homeIcon: 'icon-lvxietousushoulichachu',
    icon: 'icon-shujuzhongxin-lvxietousushoulichachu',
  },
  {
    value: 'RIGHTS_PROTECTION_CASE_STATISTICS',
    label: '维权案件统计表',
    reportType: 6,
    homeIcon: 'icon-weiquananjianyuebao',
    icon: 'icon-shujuzhongxin-weiquananjianyuebao',
  },
  {
    value: 'MAINTENANCE_LAWYER_POWER_WORK',
    label: '维护律师执业权利工作月报表',
    reportType: 5,
    homeIcon: 'icon-weihulvshizhiyequanliyuebao',
    icon: 'icon-shujuzhongxin-weihulvshizhiyequanliyuebao',
  },
]

/**
 * @description 填报常规数据
 */
export const FILL_NORMAL_AUTH_OPTIONS = [
  {
    value: 'STATISTICS_OF_LAWYERS_DATA',
    label: '律师统计表',
    reportType: 7,
    homeIcon: 'icon-lvshiqingkuangtongji',
    icon: 'icon-shujuzhongxin-lvshiqingkuangtongji',
  },
  {
    value: 'STATISTICS_OF_FIRMS_DATA',
    label: '律师事务所统计表',
    reportType: 8,
    homeIcon: 'icon-lvsuoqingkuangtongji',
    icon: 'icon-shujuzhongxin-lvsuoqingkuangtongji',
  },
  {
    value: 'FOREIGN_AFFAIRS_DATA',
    label: '涉外涉港澳台事务统计表',
    reportType: 9,
    homeIcon: 'icon-shewaishegangaoshuju',
    icon: 'icon-shujuzhongxin-shewaishegangaoshuju',
  },
  {
    value: 'CORPORATE_LAWYER_STATISTICS_DATA',
    label: '公职律师公司律师统计表',
    reportType: 10,
    homeIcon: 'icon-shuanggonglvshitongji',
    icon: 'icon-shujuzhongxin-lianggonglvshi',
  },
]

/**
 * @description 党建数据
 */
export const COLLECTION_BUILD_AUTH_OPTIONS = [
  {
    value: 'PARTY_MEMBER_CHANGE_DATA_COLLECTION',
    label: '律师行业党员异动情况统计表',
    reportType: 1,
    homeIcon: 'icon-dangyuanyidongyuebao',
    icon: 'icon-shujuzhongxin-dangyuanyidongyuebao',
  },
  {
    value: 'PARTY_MEMBER_STANDARD_DATA_COLLECTION',
    label: '律师行业党建规范化建设情况统计表',
    reportType: 2,
    homeIcon: 'icon-dangjianguifanhuayuebao',
    icon: 'icon-shujuzhongxin-dangjianguifanhuayuebao',
  },
  {
    value: 'LAWYER_INDUSTRY_PARTY_WORK_DATA_COLLECTION',
    label: '律师行业党建工作统计表',
    reportType: 15,
    homeIcon: 'icon-lvshihangyedangjiangongzuotongjibiao',
    icon: 'icon-shujuzhongxin-lvshihangyedangjiangongzuotongjibiao',
  },
]

/**
 * @description 会员数据
 */
export const COLLECTION_MEMBER_AUTH_OPTIONS = [
  {
    value: 'PUNISHED_DATA_COLLECTION',
    label: '处分决定统计表',
    reportType: 3,
    homeIcon: 'icon-chufenjuedingyuebao',
    icon: 'icon-shujuzhongxin-chufenjuedingyuebao',
  },
  {
    value: 'ASSOCIATION_COMPLAINT_WORK_DATA_COLLECTION',
    label: '律师协会投诉受理查处工作月报表',
    reportType: 4,
    homeIcon: 'icon-lvxietousushoulichachu',
    icon: 'icon-shujuzhongxin-lvxietousushoulichachu',
  },
  {
    value: 'RIGHTS_PROTECTION_CASE_STATISTICS_DATA_COLLECTION',
    label: '维权案件统计表',
    reportType: 6,
    homeIcon: 'icon-weiquananjianyuebao',
    icon: 'icon-shujuzhongxin-weiquananjianyuebao',
  },
  {
    value: 'MAINTENANCE_LAWYER_POWER_WORK_DATA_COLLECTION',
    label: '维护律师执业权利工作月报表',
    reportType: 5,
    homeIcon: 'icon-weihulvshizhiyequanliyuebao',
    icon: 'icon-shujuzhongxin-weihulvshizhiyequanliyuebao',
  },
]

/**
 * @description 省常规数据
 */
export const COLLECTION_NORMAL_AUTH_OPTIONS = [
  {
    value: 'STATISTICS_OF_LAWYERS_DATA_COLLECTION',
    label: '律师统计表',
    reportType: 7,
    homeIcon: 'icon-lvshiqingkuangtongji',
    icon: 'icon-shujuzhongxin-lvshiqingkuangtongji',
  },
  {
    value: 'STATISTICS_OF_FIRMS_DATA_COLLECTION',
    label: '律师事务所统计表',
    reportType: 8,
    homeIcon: 'icon-lvsuoqingkuangtongji',
    icon: 'icon-shujuzhongxin-lvsuoqingkuangtongji',
  },
  {
    value: 'FOREIGN_AFFAIRS_DATA_COLLECTION',
    label: '涉外涉港澳台事务统计表',
    reportType: 9,
    homeIcon: 'icon-shewaishegangaoshuju',
    icon: 'icon-shujuzhongxin-shewaishegangaoshuju',
  },
  {
    value: 'CORPORATE_LAWYER_STATISTICS_DATA_COLLECTION',
    label: '公职律师公司律师统计表',
    reportType: 10,
    homeIcon: 'icon-shuanggonglvshitongji',
    icon: 'icon-shujuzhongxin-lianggonglvshi',
  },
  {
    value: 'PL_BUSINESS_STATISTICS_DATA_COLLECTION_1',
    label: '律师业务统计表（表一）',
    reportType: 12,
    homeIcon: 'icon-a-lvshiyewucaijibiaoer',
    icon: 'icon-shujuzhongxin-lvshiyewucaijibiaoer',
  },
  {
    value: 'PL_BUSINESS_STATISTICS_DATA_COLLECTION_2',
    label: '律师业务统计表（表二）',
    reportType: 13,
    homeIcon: 'icon-a-lvshiyewucaijibiaoyi',
    icon: 'icon-shujuzhongxin-lvshiyewucaijibiaoyi',
  },
  {
    value: 'PL_POLITICS_DATA_COLLECTION',
    label: '律师参政议政统计表',
    reportType: 14,
    homeIcon: 'icon-lvshicanzhengyizheng',
    icon: 'icon-shujuzhongxin-lvshicanzhengyizheng',
  },
  {
    value: 'PL_SERVICE_DATA_COLLECTION',
    label: '律师公益法律服务统计表',
    reportType: 11,
    homeIcon: 'icon-gongyifalvfuwu',
    icon: 'icon-shujuzhongxin-gongyifalvfuwu',
  },
]

/**
 * @description 市常规数据
 */
export const CITY_COLLECTION_NORMAL_AUTH_OPTIONS = [
  {
    value: 'PL_SERVICE_DATA_COLLECTION',
    label: '律师公益法律服务统计表',
    reportType: 11,
    homeIcon: 'icon-gongyifalvfuwu',
    icon: 'icon-shujuzhongxin-gongyifalvfuwu',
  },
]

/**
 * @description 公告
 */
export const NOTICE_AUTH = [
  {
    value: 'BULLETIN_PUBLISH',
    label: '公告发布',
  },
]

/**
 * @description 系统设置
 */
export const SYSTEM_AUTH = [
  {
    value: 'SYSTEM_SETTINGS',
    label: '系统设置',
  },
]

export const REPORT_TYPE = {
  1: '律师行业党员异动情况统计表',
  2: '律师行业党建规范化建设情况统计表',
  3: '处分决定统计表',
  4: '律师协会投诉受理查处工作月报表',
  5: '维护律师执业权利工作月报表',
  6: '维权案件统计表',
  7: '律师统计表',
  8: '律师事务所统计表',
  9: '涉外涉港澳台事务统计表',
  10: '公职律师公司律师统计表',
  11: '律师公益法律服务统计表',
  12: '律师业务统计表（表一）',
  13: '律师业务统计表（表二）',
  14: '律师参政议政统计表',
  15: '律师行业党建工作统计表',
}
