import { hasAuth } from '@/utils'
import { RouteRecordRaw } from 'vue-router'

// 过滤出有权限的路由
export const filterRoutes = (routeRecordRaw: RouteRecordRaw[]) => {
	const filter = (routes: RouteRecordRaw[]) => {
		return routes.reduce<RouteRecordRaw[]>((acc, route) => {
			const routeCopy = { ...route }
			const { meta } = routeCopy
			if (hasAuth(meta?.auth) && !meta?.hideInMenu) {
				if (routeCopy.children?.length) {
					routeCopy.children = filter(routeCopy.children)
				}
				acc.push(routeCopy)
			}
			return acc
		}, [])
	}
	return filter(routeRecordRaw)
}
