/**
 * 阅读状态
 */
export const READ_STATUS = {
  1: '已读',
  2: '未读'
};

/**
 * @description 通知表格头部
 */
// export const NOTICE_COLUMNS = [
//   {
//     title: '律师行业党委设置情况-省级律师行业党委',
//     dataIndex: 'provincialLawyerParty',
//     width: 63,
//     ellipsis: true
//   }
// ];
