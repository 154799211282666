export const TOKEN_INFO = 'tokenInfo' // 用户token
export const LOGIN_INFO = 'loginInfo' // 当前用户信息
export const USER_INFO = 'userInfo' // 用户登录信息(手机号和密码)
export const DEFAULT_EXPIRED_TIME = 7 // cookie默认存储时间
export const CURRENT_NOTICE = 'currentNotice' // 当前通知（用于编辑通知）
export const MENUS = 'menus' // 菜单
export const EDIT_COLLECTION = 'editCollection' // 采集表修改（采集）
export const EXTEND_DATA = 'extendData' // 扩展信息
export enum LocalStorageKeys {
  REGION_DATA = 'regionData', // 地区选择器数据
  IS_MENU_COLLASPED = 'isMenuCollasped', // 菜单是否折叠
  GLOBAL_STATE = 'globalState', // 全局状态
}
