import { BaseOptions } from '@common/types'
import { RouteMeta } from 'vue-router'

export const getMetaTitle = (meta?: RouteMeta) => {
  if (!meta) return null
  if (typeof meta.title === 'string') {
    return meta.title
  } else if (typeof meta.title === 'function') {
    return meta.title()
  }
  return null
}
export const getValueFromObjArrByLabel = (arr: BaseOptions, label: string) => {
  return arr.find((item) => item.label === label)?.value
}
export const getValuesFromObjArrByLabels = (arr: BaseOptions, labels?: string[]) => {
  if (!labels) return arr.map((item, index) => Number(item.value))
  return labels.map((label) => getValueFromObjArrByLabel(arr, label) || 0)
}
export const getRatio = (divisor?: ValueType, dividend?: ValueType, options?: { digit?: number }) => {
  const { digit = 2 } = options || {}
  const formatedDivisor = Number(divisor)
  const formatedDividend = Number(dividend)
  if (formatedDivisor === 0) return 0
  return Number(((formatedDivisor / formatedDividend) * 100).toFixed(digit))
}
