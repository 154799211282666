/**
 * @description 全省律师参政议政统计表采集（14）
 */
export const REPORT_FIFTEEN_COLUMNS = [
  {
    title: '单位',
    dataIndex: 'unitName',
    width: 68,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '律师行业党委设置情况-省级律师行业党委',
    dataIndex: 'provincialLawyerParty',
    width: 63,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '律师行业党委设置情况-地市级律师行业党委',
    dataIndex: 'cityLawyerParty',
    width: 63,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '律师党员-合计',
    dataIndex: 'lawyerPartyTotal',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '律师党员-专职律师党员',
    dataIndex: 'soleDutyLawyerParty',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '律师党员-兼职律师党员',
    dataIndex: 'partTimeLawyerParty',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '律师党员-公司律师党员',
    dataIndex: 'companyLawyerParty',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '律师党员-公职律师党员',
    dataIndex: 'publicLawyerParty',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '律师党员-法援律师党员',
    dataIndex: 'legalLawyerParty',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '律师党员-发展党员',
    dataIndex: 'probationaryMember',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '律师党员-入党积极分子',
    dataIndex: 'partyActivists',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '律师党员-因违纪受到党纪处分的律师党员',
    dataIndex: 'partyDiscipline',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '律师事务所党组织-有3名以上正式党员所合计',
    dataIndex: 'threeTotal',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '律师事务所党组织-单独建立党组织合计',
    dataIndex: 'alonePartyOrgTotal',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '律师事务所党组织-建立党委的所',
    dataIndex: 'partyCommitteeFirm',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '律师事务所党组织-建立党总支的所',
    dataIndex: 'partyTBranchFirm',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '律师事务所党组织-建立党支部的所',
    dataIndex: 'partyBranchFirm',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '律师事务所党组织-建立联合党支部涉及律师事务所数',
    dataIndex: 'unitedPartyBranchFirm',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '律师事务所党组织-联合党支部数',
    dataIndex: 'unitedPartyBranch',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '律师事务所党组织-没有建立党组织的律师事务所',
    dataIndex: 'noPartyFirm',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '律师事务所党组织-正式党员不足3人所',
    dataIndex: 'threeBelowTotal',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '律师事务所党组织-正式党员不足3人所涉及律师事务所数',
    dataIndex: 'threeBelowFirm',
    width: 31,
    ellipsis: true,
    align: 'center'
  },

  {
    title: '律师事务所党组织-正式党员不足3人所联合党支部数',
    dataIndex: 'partyBranch',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '律师事务所党组织-正式党员不足3人没有建立党组织的律师事务所',
    dataIndex: 'noPartyBranchFirm',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '律师事务所党组织-无党员律师事务所-合计',
    dataIndex: 'noPartyLawyerFirmTotal',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '律师事务所党组织-无党员律师事务所-涉及律师事务所数',
    dataIndex: 'noPartyLawyerFirm',
    width: 31,
    ellipsis: true,
    align: 'center'
  },

  {
    title: '律师事务所党组织-无党员律师事务所-联络员，指导员人数',
    dataIndex: 'liaison',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '律师事务所党组织-无党员律师事务所-尚未选派联络员，指导员的律师事务所数',
    dataIndex: 'noLiaison',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '律师事务所党组织-律师事务所党组织隶属情况-司法行政机关党组织',
    dataIndex: 'judicialOrg',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '律师事务所党组织-律师事务所党组织隶属情况-律师协会党组织',
    dataIndex: 'associationParty',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '律师事务所党组织-律师事务所党组织隶属情况-机关工委',
    dataIndex: 'agencyWork',
    width: 31,
    ellipsis: true,
    align: 'center'
  },

  {
    title: '律师事务所党组织-律师事务所党组织隶属情况-新社会组织工委',
    dataIndex: 'societyAgencyWork',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '律师事务所党组织-律师事务所党组织隶属情况-其他',
    dataIndex: 'other',
    width: 31,
    ellipsis: true,
    align: 'center'
  },
  {
    title: '律师事务所党组织-三会一课',
    dataIndex: 'shyk',
    width: 31,
    ellipsis: true,
    align: 'center'
  }
];
