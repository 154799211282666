/**
 * @description 维权案件统计表数据表头部（6）
 */
export const REPORT_SIX_COLUMNS: {
  title: string
  dataIndex: string
  width?: number
  ellipsis: boolean
  align: string
}[] = [
  {
    title: '序号',
    dataIndex: 'index',
    width: 47,
    ellipsis: true,
    align: 'center',
  },
  {
    title: '年份',
    dataIndex: 'years',
    width: 63,
    ellipsis: true,
    align: 'center',
  },
  {
    title: '月份',
    dataIndex: 'month',
    width: 63,
    ellipsis: true,
    align: 'center',
  },
  {
    title: '所在省份',
    dataIndex: 'province',
    width: 71,
    ellipsis: true,
    align: 'center',
  },
  {
    title: '被处分人',
    dataIndex: 'assaulted',
    width: 95,
    ellipsis: true,
    align: 'center',
  },
  {
    title: '被处分人所在律所',
    dataIndex: 'firmName',
    width: 127,
    ellipsis: true,
    align: 'center',
  },

  {
    title: '违规情形（具体）',
    dataIndex: 'violation',
    width: 127,
    ellipsis: true,
    align: 'center',
  },
  {
    title: '侵犯人',
    dataIndex: 'assaultPeople',
    width: 119,
    ellipsis: true,
    align: 'center',
  },
  {
    title: '维权工作涉及到的律协',
    dataIndex: 'associationName',
    width: 159,
    ellipsis: true,
    align: 'center',
  },
  {
    title: '处理情况',
    dataIndex: 'handleSituation',
    width: 180,
    ellipsis: true,
    align: 'center',
  },
  {
    title: '现状',
    dataIndex: 'presentSituation',
    width: 104,
    ellipsis: true,
    align: 'center',
  },
]
