import { createStore, Store, useStore as baseUseStore } from 'vuex'
import { App, InjectionKey } from 'vue'
import modules from '@/store/modules'
import { cache } from '@/utils'
import { LocalStorageKeys } from '@/constants'
import { IStore } from './types'
export * from './constant'
export * from './types'
const key: InjectionKey<Store<IStore>> = Symbol('storeKey')

const store = createStore({
  modules,
})

// 定义你自己的“useStore”组合函数
export function useStore() {
  return baseUseStore(key)
}

export function setupStore(app: App) {
  app.use(store, key)
}
store.watch(
  (state) => {
    cache.set(LocalStorageKeys.GLOBAL_STATE, state)
  },
  () => { },
)

