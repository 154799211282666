/**
 * @description 数据页面类型
 */
export enum StatisticsPageEnum {
  PAGE_AUTH_LIST = 'auth-list', // 表权限列表
  PAGE_TABLE_TIME_LIST = 'time-list', // 表时间列表
  PAGE_FORM = 'form', // 编辑页
  PAGE_TABLE_LOOK_COLLECTION = 'table-look-collection', // 查看表（数据采集）
  PAGE_TABLE_DETAIL_LIST = 'table-detail-list', // 查看详情列表
  PAGE_TABLE_LOOK_SUBMIT = 'table-look-submit' // 查看表（数据报送）
}

/**
 * @description 发布对象
 */
export enum SendObject {
  OBJECT_ASSOCIATION = 1, // 全省地市律协
  OBJECT_FIRM = 2 // 全省律所
}
// 发布对象
export enum PublishObject {
  LAW_ASSOCIATION = 1,//地市律协
  LAW_FIRM//全省律所
}
export const PublishObjectMap = {
  [PublishObject.LAW_ASSOCIATION]: '地市律协',
  [PublishObject.LAW_FIRM]: '全省律所'
}
